import { Component, createRef, RefObject } from 'react';
import * as React from 'react';
import { ImageIcon } from '../ImageIcon'
import { UploadDocument, UploadDocumentForm } from '../../DataAccess'
import { IUploadDocument } from '../../interface/Interface'
import { IRequestDokumentItem , IRequestDokumentWithoutDocumentItem} from '../../interface/Interface'
import { LoadingAnimation } from '../../component/loadingComponent'

interface FileUploaderProps {

    handleoptUpload: (event: IUploadDocument) => void,
    handleDownload: (event: number) => void,
    beschreibung: string
    filename: string
    documentid: number
    clientid: number
    accesstoken: string
    stgguid: string
}

interface FileUploaderState {
    accessToken: string
    filename: string
    dokuid: number
    clientid: number
    fileinputid: string
    aid: string
    error: string
    beschreibung: string
    fileUpload: RefObject<HTMLInputElement>
    ready: boolean
}

export class FileOptUploader extends Component<FileUploaderProps, FileUploaderState>{
    constructor(props: FileUploaderProps) {
        super(props);
        // this.handleChange = this.handleChange.bind(this);
        this.state = {
            beschreibung: this.props.beschreibung,
            error: '', accessToken: '-1',
            filename: this.props.filename,
            dokuid: this.props.documentid,
            clientid: this.props.clientid,
            fileinputid: 'optfile' + this.props.clientid.toString(),
            aid: 'aoptfile' + this.props.clientid,
            fileUpload: createRef<HTMLInputElement>(),
            ready : true
        }
    };

    handleuploadDokument = async (event: IRequestDokumentWithoutDocumentItem) =>
    {
        await UploadDocument(this.props.accesstoken,JSON.stringify(event)).then(
            res=> {
                let TTPBewerberItem = JSON.parse(JSON.stringify(res));
                if(TTPBewerberItem.ID > 0)
                {
                     this.setState({dokuid : TTPBewerberItem.ID, filename:event.filename })
                     this.props.handleoptUpload({clientid : this.state.clientid, filename: event.filename,  bewerbungsunterlageID : 0, dokumentid: TTPBewerberItem.ID, beschreibung : this.state.beschreibung})
                }
                
            }
        )
    }

    handleClick = async (event: any) => {
        this.setState({  ready: false,  error: '' });
        let obj = this.state.fileUpload.current;
        try {
            if (this.state.beschreibung.trim() === '') {
                   this.setState({ error: 'Please enter the description.' , ready : true});
               } else {
            if (obj && obj.files && obj.files[0]) {
                let file = obj.files[0];
                var ext = file.name.substring(file.name.lastIndexOf('.') + 1);
                if (["jpg", "pdf", "jpeg"].some(value => value === ext.toLowerCase()) && file.size < 5300000) {
                    const event: IRequestDokumentItem = {
                        beschreibung: this.state.beschreibung,
                        dokumentID: this.state.dokuid,
                        stgguid: this.props.stgguid,
                        filename: file.name,
                        bewerbungsunterlageid: 0,
                        document: file,
                    }
                    await UploadDocumentForm(this.props.accesstoken, event).then(
                        res => {
                            let TTPBewerberItem = JSON.parse(JSON.stringify(res));
                            if (TTPBewerberItem.ID > 0) {
                                this.setState({ dokuid: TTPBewerberItem.ID, filename: event.filename })
                                this.props.handleoptUpload({
                                    clientid: this.state.clientid,
                                    filename: event.filename,
                                    bewerbungsunterlageID: 0,
                                    dokumentid: TTPBewerberItem.ID,
                                    beschreibung: this.state.beschreibung
                                })
                                this.setState({  ready: true });
                            }
                        }
                    )
                
            } else {
                this.setState({ error: 'This file type is not allowed. Please use the following file types with a maximum size of 5 MB: jpg, jpeg, pdf', ready:true });
            }
        }                   if (this.state.dokuid > 0) {
                                await this.handleuploadDokument({
                                    beschreibung: this.state.beschreibung, document: '',
                             dokumentID: this.state.dokuid, stgguid: this.props.stgguid, filename: '', bewerbungsunterlageid: 0
                                })
                                this.setState({  ready: true });
                             }
    }
        } finally { this.setState({  ready: true });}
    }


    // handleClick = async (event: any) => {
    //     this.setState({ error: '' });
    //     let obj = document.getElementById(this.state.fileinputid) as HTMLInputElement;
    //     try {
    //         if (this.state.beschreibung.trim() === '') {
    //             this.setState({ error: 'Please enter the description.' });
    //         } else {

    //             if (obj.files !== null && obj.files !== undefined) {
    //                 let file = obj.files[0];
    //                 var ext = file.name.substring(file.name.lastIndexOf('.') + 1);
    //                 if (ext.toLowerCase() === "jpg" || ext.toLowerCase() === "jpeg" || ext.toLowerCase() === "pdf") {
    //                     let reader = new FileReader();
    //                     reader.onloadend = async () => {
    //                         if (typeof reader.result === "string") {

    //                             await this.handleuploadDokument({
    //                                 beschreibung: this.state.beschreibung, document: reader.result.split(",")[1],
    //                                 dokumentID: this.state.dokuid, stgguid: this.props.stgguid, filename: file.name, bewerbungsunterlageid: 0
    //                             })

    //                         }
    //                     }
    //                     reader.readAsDataURL(file);
    //                 } else {
    //                     this.setState({ error: 'This file type is not allowed. Please use the following file types with a maximum size of 5 MB: jpg, jpeg, pdf' });

    //                 }
    //             } else {
    //                 if (this.state.dokuid > 0) {
    //                     await this.handleuploadDokument({
    //                         beschreibung: this.state.beschreibung, document: null,
    //                         dokumentID: this.state.dokuid, stgguid: this.props.stgguid, filename: '', bewerbungsunterlageid: 0
    //                     })
    //                 }
    //             }
    //         }
    //     } catch{

    //     }

    // }


    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        let beschreibung = this.state.beschreibung;
        beschreibung = event.target.value;
        this.setState({ beschreibung });
    }

    render() {
        if (!this.state.ready)
        return <LoadingAnimation />;
  
        return <table id="uploadPanel" className="AdditionalFiles UploadTable uploadTable">
            <tbody id={'li_opt_' + this.props.clientid}>
                <tr>
                </tr>
                <tr>
                    <td className="statusIcon">
                        {this.state.dokuid > 0 && <ImageIcon BooleanValue={this.state.dokuid > 0}></ImageIcon>}
                    </td>
                    <th className="description">
                        <label htmlFor={'Opt_Doku_Beschreibung_TB1' + this.props.clientid}>
                            Description <em title="Required" className="fieldRequired">*</em>
                        </label>
                        <input name={"Opt_Doku_Beschreibung_TB" + this.props.clientid} type="text" value={this.state.beschreibung} onChange={this.handleInputChange} id={"Opt_Doku_Beschreibung_TB" + this.props.clientid} className="optFile"></input>
                    </th>
                    <td className="uploadField">

                        <input type="file" ref={this.state.fileUpload} id={this.state.fileinputid} accept="image/jpg, image/jpeg" />
                        <button type="button" name={'P_Upload_Button' + this.props.clientid} onClick={(e) => this.handleClick(e)} id={'Opt_Upload_Button' + this.props.clientid} className="button orange mt-0">
                            {this.state.dokuid > 0 && <label>Change</label>}
                            {this.state.dokuid <= 0 && <label>Upload</label>}
                        </button>
                    </td>
                    <td className="fileInfo">
                        {this.state.error !== '' && <span style={{ color: "red", float: "left" }}>{this.state.error}</span>}
                        {this.state.dokuid > 0 && <a id={this.state.aid} href={"#"}
                            onClick={(e) => this.props.handleDownload(this.state.dokuid)}
                        >{this.state.filename}</a>}
                    </td>
                </tr>
            </tbody>
        </table>
    }
}