import * as React from 'react';
import { IDokument } from '../../interface/Interface'
import {FileOptUploader} from '../optionaleDocuments/FileOptUploader'
import {IUploadDocument} from '../../interface/Interface'

interface optDocumentTableProps {
    handleDownload : (event : number) => void,
    accessToken: string
    onListChange : (languageskillstext : IDokument[])  => void
    optionaldocumentlist: IDokument[]
    stgguid : string
    
}

interface State {
    accessToken: string
    optionaldocumentlist: IDokument[]
    ready: boolean
}

export class OptDocumentTable extends React.Component<optDocumentTableProps, State> {
    constructor(props: optDocumentTableProps) {
        super(props);

        this.state = {
            accessToken: props.accessToken ? props.accessToken : "",
            ready: false,
            optionaldocumentlist:this.props.optionaldocumentlist
        }
    }

      clickaddhandlerForUpload = async () => {

    let  doc  = this.state.optionaldocumentlist;
    doc.map((value, index) => doc[index].clientId = index)
    await this.setState({ optionaldocumentlist: doc });
   
    await this.setState((prevState) => ({
        optionaldocumentlist: [...prevState.optionaldocumentlist, {clientId: (this.state.optionaldocumentlist.length) ? this.state.optionaldocumentlist[this.state.optionaldocumentlist.length-1].clientId+1 : 1,beschreibung:'',bewerbungsunterlageID:0,bewerbungsunterlagebezeichnung:'',dokumentid:0,filename:'',isoptional:true,typ:'', isessay:false}],
      }));
      this.props.onListChange(this.state.optionaldocumentlist);
}

handleupload = async (event: IUploadDocument) =>
  {
    
      let dokument = this.state.optionaldocumentlist;
      var _index = dokument.findIndex(value  => value.clientId === event.clientid);
      const _l = dokument[_index];
      
      _l.dokumentid = event.dokumentid;
      _l.filename = event.filename;
      _l.beschreibung = event.beschreibung;

      await this.setState({optionaldocumentlist : dokument});
      this.props.onListChange(this.state.optionaldocumentlist);
    }
  

    render() {

        return (
            <fieldset id="fieldset_opt">
              <legend>
              Other documents (optional)
              </legend>
              <p className="discreet">
              Please enter a description of the document (e.g. course, other qualification, seminar) before you upload it.

              By using the "Change" button, you can modify the description or change the uploaded document.
              </p>
              <ol className="nthList form" >
              {this.state.optionaldocumentlist.map((value, index) =>   <li key={index} className="w-100"><FileOptUploader handleDownload={this.props.handleDownload} handleoptUpload={this.handleupload} accesstoken={this.props.accessToken} stgguid={this.props.stgguid} beschreibung={value.beschreibung} clientid={value.clientId} documentid={value.dokumentid} filename={value.filename} ></FileOptUploader></li>)}
              </ol>
              <button type="button" className="buttonicon" onClick={this.clickaddhandlerForUpload} ><span className="icon-wrapper yellow" id="Sprache2ImagePlus"><i className="icon fas fa-plus"></i></span></button>
              </fieldset>


        )

    }

}