import * as React from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import { getaccesToken, UploadFotoForm, getMotivationGuid } from '../DataAccess';
import 'react-image-crop/dist/ReactCrop.css';
import iconfalse from '../images/icon_error.png';
import Footer from '../component/footer'
import { LoadingAnimation } from '../component/loadingComponent'
import { HeaderLogoIntern } from '../component/HeaderLogo'
import { RouteComponentProps } from "react-router";
import { isUndefined } from 'util';
import { IRequestFotoItem } from '../interface/Interface'

interface TestState {
    crop?: ReactCrop.Crop,
    src: string,
    imageref: HTMLImageElement,
    imagesrc: string,
    submiterror: boolean,
    disabled: boolean,
    submitted: boolean,
    accessToken: string,
    ready: boolean, timeout: boolean, error: string
    stgguid: string
    firststart: boolean
    resultbase64: string
    savefile: Blob
}

export default class FotoUpload extends React.Component<RouteComponentProps<any>, TestState> {
    constructor(props: any) {
        super(props)
        this.state = {
            crop: {
                x: 0,
                y: 0,
                aspect: 3 / 4,
                width: 200,
                height: 150,
            }, src: iconfalse,
            imageref: new (window as any).Image(),
            imagesrc: iconfalse,
            ready: false,
            accessToken: '-1',
            disabled: false,
            error: '',
            submiterror: false,
            submitted: false,
            timeout: false,
            stgguid: '',
            firststart: true,
            resultbase64: '',
            savefile: new Blob()
        }
        setTimeout(() => this.setState({ timeout: true }), 30000);
        let guid = this.getGuid();
        if ((guid === "" || guid === null)) {
            this.props.history.push('/');
        }
        this.checkaccesstoken(guid);
    }

    getGuid(): string {
        let data = sessionStorage.getItem('applicationguid');
        return data || "";
    }

    getUrlParams(): URLSearchParams {
        if (!this.props.location.search) return new URLSearchParams();
        return new URLSearchParams(this.props.location.search);
    }

    locationError() {
        this.props.history.push({
            pathname: '/error'
        })
    }

    checkaccesstoken = async (guid: string) => {

        if (this.state.accessToken === "-1" || this.state.accessToken === null) {
            await getaccesToken().then(res => {
                    if (res !== null && res !== isUndefined && typeof res === "string") {
                        this.setState({ accessToken: res.toString() })
                        this.checkGuid(guid);
                    }
                })
        } else await this.checkGuid(guid);
    }

    checkGuid = async (guid: string) => {


        await getMotivationGuid(this.state.accessToken, guid)
            .then(res => {

                let TTPBewerberItem = JSON.parse(JSON.stringify(res));
                let stgguid = this.state.stgguid;
                stgguid = TTPBewerberItem.StrGuid;

                this.setState({ stgguid: stgguid, ready: true });
                //console.log(this.state.imagesrc)
                //return;
                if (this.state.stgguid !== guid) {
                    this.props.history.push({
                        pathname: '/',
                        state: { accessToken: this.state.accessToken }
                    })
                } else {

                    this.setState({ stgguid: stgguid, ready: true });
                }

            })
    }

    onChange = (crop: any) => {
        this.setState({ crop });
    }

    onSelectFile = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                if (typeof reader.result === "string")
                    this.setState({ src: reader.result, firststart: false });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    onCropChange = (crop: Crop) => {
        // console.log('onCropChange', crop);
        this.setState({ crop });
    }

    handleImageLoaded = (image: any) => {
        this.setState({ imageref: image })
    }

    handleonCropComplete = (crop: any) => {

        this.makeClientCrop(crop)
    }

    makeClientCrop = async (crop: ReactCrop.Crop) => {
        if (this.state.imageref && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.state.imageref,
                crop,
                "newFile.jpeg"
            );
            this.setState({ imagesrc: croppedImageUrl as string });



        }
    }

    getCroppedImg = async (image: HTMLImageElement, crop: ReactCrop.Crop, fileName: string) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        if (crop.width) canvas.width = crop.width;
        if (crop.height) canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        if (!ctx) return;
        if (crop.x && crop.y && crop.width && crop.height) {
            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );
        }

        var dataURL = canvas.toDataURL("image/jpeg");
        let base64 = dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");

        await this.setState({ resultbase64: base64 });

        return new Promise((resolve, reject) => {
            let fileurl = ''
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }

                this.setState({ savefile: blob });

                //blob.name = fileName;
                window.URL.revokeObjectURL(fileurl);
                fileurl = window.URL.createObjectURL(blob);
                resolve(fileurl);
            }, "image/jpeg");
        });
    }

    // handleuploadDokument = async (event: IRequestFotoItem) => {
    //     await UploadFoto(this.state.accessToken, JSON.stringify(event)).then(
    //         res => {
    //             let TTPBewerberItem = JSON.parse(JSON.stringify(res));
    //             if (TTPBewerberItem.ID > 0) {
    //                 this.props.history.push({
    //                     pathname: '/documents',
    //                     search: 'applicationguid=' + this.state.stgguid,
    //                     state: { accessToken: this.state.accessToken }
    //                 })
    //             }

    //             else {
    //                 this.setState({ disabled: false, submiterror: true });
    //             }

    //         }
    //     )
    // }

    clickSubmithandler = async (event: any) => {

        event.preventDefault();
        this.setState({ disabled: true, submiterror: false })
        console.log("clickSubmithandler");
        if (this.state.resultbase64 !== '') {

            if (this.state.accessToken !== "-1") {

                const event: IRequestFotoItem = {
                    document: this.state.savefile,
                    stgguid: this.state.stgguid,
                    filename: 'photo.jpeg'
                }
                await UploadFotoForm(this.state.accessToken, event).then(
                    res => {
                        let TTPBewerberItem = JSON.parse(JSON.stringify(res));
                        if (TTPBewerberItem.ID > 0) {
                            this.props.history.push({
                                pathname: '/documents',
                                search: 'applicationguid=' + this.state.stgguid,
                                state: { accessToken: this.state.accessToken }
                            })
                        }

                        else {
                            this.setState({ disabled: false, submiterror: true });
                        }
                    }
                )

                // await this.handleuploadDokument({ document: this.state.savefile, 
                //     stgguid: this.state.stgguid, 
                //     filename: 'photo.jpeg' });
            }
        } else {
            this.setState({ disabled: false })
        }
    }

    clickBackhandler = async (event: any) => {

        event.preventDefault();
        this.setState({ disabled: false, submiterror: false })
        this.props.history.push({
            pathname: '/documents',
            search: 'applicationguid=' + this.state.stgguid,
            state: { accessToken: this.state.accessToken }
        })

    }

    render() {
        if (this.state.timeout && !this.state.ready)
            this.locationError();
        if (!this.state.ready)
            return <LoadingAnimation />;
        return <div id="visual-portal-wrapper" className="clearfix">
            <HeaderLogoIntern />
            <div id="portal-columns" className="row">
                <div id="portal-columns-content" className="container">
                    <h1>IMC Krems online application system</h1>
                    <p className="description">
                        Photo upload
                         </p>
                    <fieldset id="Fieldset_Foto">
                        <legend><span id="Label_Fieldset_P"> <p className="discreet"> Please consider the format specifications for your photo - proportionally scaled in portrait mode (3:4 aspect ratio).</p></span></legend>
                        <div id="div_fotouploader">
                            {this.state.firststart && <div id="pnlUpload" className="subform">
                                <ul className="w-100">
                                    <li className="w-100">
                                        <input type="file" onChange={this.onSelectFile} />
                                    </li>
                                </ul>  </div>}
                            <div className="mt-1">

                            </div>
                            <div className="row">
                                <div className="image-cropper">

                                    {!this.state.firststart &&
                                        <div id="div_fotocropper" style={{ maxWidth: "500px" }}  >

                                            <ReactCrop
                                                src={this.state.src}
                                                crop={this.state.crop}
                                                onChange={this.onChange}
                                                onImageLoaded={this.handleImageLoaded}
                                                onComplete={this.handleonCropComplete}
                                            />
                                        </div>}

                                    {!this.state.firststart &&
                                        <div id="div_fotocropper">
                                            <img alt="Crop" style={{ maxWidth: "300px" }} src={this.state.imagesrc} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </fieldset><div className="row"></div>
                    <p className="discreet"></p>
                    <div className="formControls clearfix" id="foto_button">
                        <fieldset>
                            <ul className="form">
                                <li>
                                    <button type="button" id="ButtonBack" className="button blue left w-100" disabled={this.state.disabled}
                                        onClick={this.clickBackhandler}>
                                        Back
                                    </button>
                                </li>
                                {!this.state.firststart && <li className="w-100" id="foto_button_submit">
                                    <button type="button" id="_Buttonright" className="button orange right" disabled={this.state.disabled}
                                        onClick={this.clickSubmithandler}>
                                        Confirm and upload
                                    </button>
                                </li>}
                            </ul>
                        </fieldset>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    }
}