import { Component, ChangeEvent } from 'react';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser'; 

interface MotivationFieldTextProps {

    value: string
    handleInputChange: (event : React.ChangeEvent<HTMLTextAreaElement>,  ref_studiengaenge_BewerbungMotivationschreibenID : number) => void,
    handleInputChangeForEssay: (event : React.ChangeEvent<HTMLTextAreaElement>,  ref_studiengaenge_BewerbungMotivationschreibenID : number) => void,
    errorvalue?: string
    Text: string
    required?: boolean
    clientid : number
    maxlenght : number
    lettercount : number
    ref_studiengaenge_BewerbungMotivationschreibenID : number
    ClickSaveOneField: (event : any,  ref_studiengaenge_BewerbungMotivationschreibenID : number) => void
    index: number
    autofocusIndex: number
    isessay: boolean
    essayvalue: string
    essayfrage : string
}

interface State {
    required: boolean
}

export class MotivationField extends Component<MotivationFieldTextProps, State>{
    constructor(props: MotivationFieldTextProps) {
        super(props);

        this.state = {
            required : props.required ? props.required : false
        }
    };

   

    render() {

        let startRow = ((this.props.maxlenght > 1000 ? 1000 :this.props.maxlenght) /40);
        if(this.props.value !== null || this.props.value !== '')
        {
            let addzahl = this.props.value.length /100;
            let calculaterow =  this.props.value.split(/\n /).length + 1; 
            let resultrow = calculaterow > addzahl ? calculaterow : addzahl;
            startRow = resultrow > startRow ? resultrow : startRow;
        }

        let startRowQuelle = 5;
        if(this.props.essayvalue !== null || this.props.essayvalue !== '')
        {
            let addzahlessay = this.props.essayvalue.length /100;
            let calculaterowessay =  this.props.essayvalue.split(/\n /).length + 1; 
            let resultrowessay = calculaterowessay > addzahlessay ? calculaterowessay : addzahlessay;
            startRowQuelle = resultrowessay > startRowQuelle ? resultrowessay : startRowQuelle;
        }

        return (
            <fieldset id={'Mot_' + this.props.clientid }  >
             <label className="bg-grey motiv" htmlFor={'Frage_' + this.props.clientid }>
             { ReactHtmlParser (this.props.Text) } 
             </label>

{this.props.index === this.props.autofocusIndex && 

             <textarea rows={ Math.ceil(startRow)} cols={40} 
             autoFocus
             value={this.props.value} id={'tbmot_' + this.props.clientid } onChange={ (e) => this.props.handleInputChange(e, this.props.ref_studiengaenge_BewerbungMotivationschreibenID)}   ></textarea>
             
}

{this.props.index !== this.props.autofocusIndex && 

<textarea rows={ Math.ceil(startRow)} cols={40} 
value={this.props.value} id={'tbmot_' + this.props.clientid } onChange={ (e) => this.props.handleInputChange(e, this.props.ref_studiengaenge_BewerbungMotivationschreibenID)}   ></textarea>

}
             
             <span className="typed-signs">characters: {this.props.lettercount}</span>
{this.props.isessay && 
<div style={{textAlign: "left", wordWrap: "break-word"}}>
<label className="bg-grey motiv">
{ ReactHtmlParser (this.props.essayfrage) } 
             </label>
             <textarea rows={ Math.ceil(startRowQuelle)} cols={40}
             value={this.props.essayvalue} 
             id={'tb_quelle_' + this.props.clientid }
             onChange={ (e) => this.props.handleInputChangeForEssay(e, this.props.ref_studiengaenge_BewerbungMotivationschreibenID)} 
             ></textarea>
    </div>
    }
             <button onClick={ (e) => this.props.ClickSaveOneField(e, this.props.ref_studiengaenge_BewerbungMotivationschreibenID)}  type="button" id={'_ButtonSave_' + this.props.clientid } style={{margin:'0px !important', float: 'right', width:'50%'}} className="button orangemotsmall right" >Save</button>
            </fieldset>
        );

    }
}