import { Component, createRef, RefObject } from 'react';
import * as React from 'react';
import { ImageIcon } from './ImageIcon'
import { UploadFotoFileForm, UploadDocumentForm } from '../DataAccess'
import { IUploadDocument } from '../interface/Interface'
import { IRequestDokumentItem, IRequestFotoFileItem } from '../interface/Interface'
import { LoadingAnimation } from '../component/loadingComponent'
import { Tooltip } from '@progress/kendo-react-tooltip';
import { TooltipContentFotoUpload } from './ToolTipContentFotoUpload'

interface FileUploaderProps {
    handleUpload: (event: IUploadDocument) => void,
    handleDownload: (event: number) => void,
    handleFotoDownload: (event: number) => void,
    handleRedirectFotoUpload: (event: number) => void,
    handleRedirectMotivationschreiben: (event: number) => void,
    typ: string
    htmlname: string
    Text: string
    filename: string
    documentid: number
    bewerbungsunterlageid: number
    clientid: number
    accesstoken: string
    allowtyp: string[]
    stgguid: string
    isoptional: boolean
    isessay: boolean
}

interface FileUploaderState {
    accessToken: string
    filename: string
    dokuid: number
    clientid: number
    fileinputid: string
    aid: string
    error: string
    bewerbungsunterlageid: number
    fileUpload: RefObject<HTMLInputElement>
    fotoUpload: RefObject<HTMLInputElement>
    ready: boolean
    

}

export class FileUploader extends Component<FileUploaderProps, FileUploaderState>{
    constructor(props: FileUploaderProps) {
        super(props);
        // this.handleChange = this.handleChange.bind(this);
        this.state = {
            bewerbungsunterlageid: this.props.bewerbungsunterlageid,
            error: '',
            accessToken: '-1',
            filename: this.props.filename,
            dokuid: this.props.documentid,
            clientid: this.props.clientid,
            fileinputid: 'file' + this.props.clientid.toString(),
            aid: 'afile' + this.props.clientid,
            fileUpload: createRef<HTMLInputElement>(),
            fotoUpload : createRef<HTMLInputElement>(),
            ready : true
        }
    };

    handleClick = async (event: any) => {
        this.setState({  ready: false,  error: '' });
        let fileUpload = this.state.fileUpload.current;
        try {
            if (fileUpload && fileUpload.files && fileUpload.files[0]) {
                let file = fileUpload.files[0];
                var ext = file.name.substring(file.name.lastIndexOf('.') + 1);
                if (["jpg", "pdf", "jpeg"].some(value => value === ext.toLowerCase()) && file.size < 5300000 ) {
                    
                    const event: IRequestDokumentItem = {
                        beschreibung: this.props.Text,
                        document: file,
                        dokumentID: this.state.dokuid,
                        stgguid: this.props.stgguid,
                        filename: file.name,
                        bewerbungsunterlageid: this.props.bewerbungsunterlageid
                    }
                    await UploadDocumentForm(this.props.accesstoken, event).then(
                        res => {
                            let TTPBewerberItem = JSON.parse(JSON.stringify(res));
                            if (TTPBewerberItem.ID > 0) {
                                this.setState({ dokuid: TTPBewerberItem.ID, filename: event.filename })
                                this.props.handleUpload({
                                    beschreibung: this.props.Text,
                                    clientid: this.state.clientid,
                                    filename: event.filename,
                                    bewerbungsunterlageID: this.props.bewerbungsunterlageid,
                                    dokumentid: TTPBewerberItem.ID
                                })
                                this.setState({  ready: true });
                            }
                        }
                    )
                
            } else {
                this.setState({ error: 'This file type is not allowed. Please use the following file types with a maximum size of 5 MB: jpg, jpeg, pdf' });
                this.setState({  ready: true });
            }
        }else{
            this.setState({  ready: true });
        }
        } finally {this.setState({  ready: true });}
   
    }

    handleFotoClick = async (event: any) => {
        this.setState({  ready: false , error: ''});
        let fileUpload = this.state.fotoUpload.current;
        try {
            if (fileUpload && fileUpload.files && fileUpload.files[0]) {
                let file = fileUpload.files[0];
                var ext = file.name.substring(file.name.lastIndexOf('.') + 1);
                if (["jpg", "jpeg"].some(value => value === ext.toLowerCase()) && file.size < 3200000) {

                    const event: IRequestFotoFileItem = {
                        document: file,
                        stgguid: this.props.stgguid,
                        filename: 'photo.jpeg'
                    }
                    await UploadFotoFileForm(this.props.accesstoken, event).then(
                        res => {
                            let TTPBewerberItem = JSON.parse(JSON.stringify(res));
                            this.setState({ dokuid: TTPBewerberItem.ID, filename: 'photo.jpeg' })
                            this.props.handleUpload({
                                beschreibung: '',
                                clientid: this.state.clientid,
                                filename: event.filename,
                                bewerbungsunterlageID: 0,
                                dokumentid: TTPBewerberItem.ID
                            })
                            this.setState({  ready: true });
    
                        }
                    )
                
            } else {
                this.setState({ error: 'This file type is not allowed. Please use the following file types with a maximum size of 3 MB: jpg, jpeg' });
                this.setState({  ready: true });
            }
        }else{
            this.setState({  ready: true });
        }
        } finally {this.setState({  ready: true });}
    }


    sleep = (milliseconds : any) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
render() {
    let str_motivation = 'Statement of motivation';
    let text = (this.props.isoptional) ? this.props.Text + ' (optional)' : this.props.Text;
    if (!this.state.ready)
      return <LoadingAnimation />;

    return (
        <table id="uploadPanel" className="uploadTable">
            <tbody id={'li_P_' + this.props.clientid} >
                <tr className="header">
                    <th colSpan={3}>
                        {text}{this.props.isoptional === false &&<em title="Required" className="fieldRequired" id="reqmaturadatum">*</em>}
                        {this.props.typ === 'foto' && this.props.Text.includes(str_motivation) === false &&
                        
                        <div style={{ float: "right" }}>
                        <Tooltip content={(props: any) => <TooltipContentFotoUpload title='info' />} openDelay={100} position="top" anchorElement="target" >
                            <div style={{ float: "left", position: 'relative', width: '12px', height: '12px',marginLeft: '-530px' }}>
                                <a style={{ width: '20px', height: '20px' }} className="icon fas fa-info-circle" href="#" title='info'></a>
                            </div>
                        </Tooltip>
                    </div>
                             }
   
                    </th>

                </tr>
                <tr>
                    <td className="statusIcon">
                        <ImageIcon BooleanValue={this.state.dokuid > 0}></ImageIcon>
                    </td>
                    <td className="uploadField">
                        {this.props.typ !== 'foto' && this.props.Text.includes(str_motivation) === false &&
                            <input type="file" ref={this.state.fileUpload} id={this.state.fileinputid} accept="image/jpg, image/jpeg, application/pdf" />
                        }
                        {this.props.typ !== 'foto' && this.props.Text.includes(str_motivation) === false &&
                            <button type="button" name={'P_Upload_Button' + this.props.clientid}
                                onClick={(e) => this.handleClick(e)} id={'P_Upload_Button' + this.props.clientid} className="button orange mt-0">
                                Upload
                                        </button>
                        }


                        {this.props.typ === 'foto' && this.props.Text.includes(str_motivation) === false &&
                        <input type="file" ref={this.state.fotoUpload} id={this.state.fileinputid + "foto"} accept="image/jpg, image/jpeg" />
                        }
                        {this.props.typ === 'foto' && this.props.Text.includes(str_motivation) === false &&
                        <button type="button" name={'P_Upload_ButtonFoto' + this.props.clientid}
                            onClick={(e) => this.handleFotoClick(e)} id={'P_Upload_ButtonFoto' + this.props.clientid} className="button orange mt-0">
                            Upload
                                    </button>
                        }

                        {this.props.typ !== 'foto' && this.props.Text.includes(str_motivation) === true && this.props.isessay === true &&
                            <button type="button" name={'motivationuploadbutton' + this.props.clientid}
                                onClick={(e) => this.props.handleRedirectMotivationschreiben(this.props.bewerbungsunterlageid)}
                                id={'motivationuploadbutton' + this.props.clientid} className="button orange mt-0">
                                Compose/Modify Statement of motivation and essay
                                        </button>
                        }
                        {this.props.typ !== 'foto' && this.props.Text.includes(str_motivation) === true && this.props.isessay === false &&
                            <button type="button" name={'motivationuploadbutton' + this.props.clientid}
                                onClick={(e) => this.props.handleRedirectMotivationschreiben(this.props.bewerbungsunterlageid)}
                                id={'motivationuploadbutton' + this.props.clientid} className="button orange mt-0">
                                Compose/Modify Statement of motivation
                                        </button>
                        }
                    </td>
                    <td className="fileInfo">
                        {this.state.error !== '' && <span style={{ color: "red", float: "left" }}>
                            {this.state.error}
                        </span>}
                        {this.state.dokuid > 0 && this.props.typ !== 'foto' && <a id={this.state.aid} onClick={(e) => this.props.handleDownload(this.state.dokuid)}>
                            {this.state.filename}
                        </a>}
                        {this.state.dokuid > 0 && this.props.typ === 'foto' && <a id={this.state.aid} onClick={(e) => this.props.handleFotoDownload(this.state.dokuid)}>
                            {this.state.filename}
                        </a>}
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
}