import { Tooltip } from '@progress/kendo-react-tooltip';
import { Component, ChangeEvent } from 'react';
import * as React from 'react';

interface InputFieldTextProps {

    value: string,
    handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void,
    errorvalue?: string,
    htmlname: string,
    Text: string,
    Type: string,
    required?: boolean
    istooltip?: boolean
    tooltiptext?: string,
    tabindex: number
}

interface State {
    istooltip: boolean
    tooltiptext: string
    required: boolean
}

export class InputFieldText extends Component<InputFieldTextProps, State>{
    constructor(props: InputFieldTextProps) {
        super(props);

        this.state = {
            istooltip: props.istooltip ? props.istooltip : false,
            tooltiptext: props.tooltiptext ? props.tooltiptext : "",
            required : props.required ? props.required : false

        }
    };

    render() {
        return (
            // <li>
            <div className="col-12 col-md form-group form-item">
                <label htmlFor={this.props.htmlname}>
                   <span >{this.props.Text}</span>
                    {this.state.required && <em title="Required" className="fieldRequired">*</em>}
                </label>
                {this.state.istooltip && <div style={{ float: "right"}}>
                    <Tooltip openDelay={100} position="top" anchorElement="target">
                        <div style={{ float: "left", width: '12px', height: '12px', marginLeft: '-20px' }}>
                          
                            <a style={{ width: '20px', height: '20px' }} className="icon fas fa-info-circle" href="#" title={this.state.tooltiptext} ></a>
                        </div>
                    </Tooltip>
                </div>}
                <input style={{ float: "left" }} type={this.props.Type} tabIndex={this.props.tabindex} id={this.props.htmlname} name={this.props.htmlname} value={this.props.value} onChange={this.props.handleInputChange} />

                <span style={{ color: "red",  float: "left"  }}>{this.props.errorvalue}</span>
            </div>
            // </li>
        );
    }
}