import * as React from 'react'
import { Tooltip } from '@progress/kendo-react-tooltip';

interface InputFieldTextProps {

    selectedDropdown: (event: React.ChangeEvent<HTMLSelectElement>) => void,
    errorvalue?: string,
    htmlname: string,
    Text: string,
    data: any[],
    value: string,
    required?: boolean,
    istooltip?: boolean,
    tooltiptext?: string,
    tabindex: number
}


interface State {
    istooltip: boolean
    tooltiptext: string
    required: boolean
    errorvalue : string
}

export class InputFieldSelect extends React.Component<InputFieldTextProps, State>{
    constructor(props: InputFieldTextProps) {
        super(props);
        this.state = {
            istooltip: props.istooltip ? props.istooltip : false,
            tooltiptext: props.tooltiptext ? props.tooltiptext : "",
            required : props.required ? props.required : false,
            errorvalue: props.errorvalue ? props.errorvalue : ""
        }
    };
    render() {
        return (
            <div className="col-12 col-md form-group form-item">
                 <label htmlFor={this.props.htmlname}>
                    <span>{this.props.Text}</span>
                    {this.state.required && <em title="Required" className="fieldRequired">*</em>}
                </label>
                {this.state.istooltip && <div style={{ float: "right"}}>
                    <Tooltip openDelay={100} position="top" anchorElement="target">
                    <div style={{ float: "left", width: '12px', height: '12px', marginLeft: '-20px' }}>
                            <a style={{ width: '20px', height: '20px' }} className="icon fas fa-info-circle" href="#" title={this.state.tooltiptext} ></a>
                        </div>
                    </Tooltip>
                </div>}
                <select style={{ float: "left" }} required={true} tabIndex={this.props.tabindex} id={this.props.htmlname} name={this.props.htmlname} onChange={this.props.selectedDropdown} value={this.props.value} >
                    {this.props.data.map((value, index) => <option key={index} value={value.value}>{value.text}</option>)}
                </select>

                <span style={{ color: "red" ,  float: "left" }}>{this.props.errorvalue}</span>
            </div>
        );
    }
}