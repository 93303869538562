import * as React from 'react'
import {StudiengangsInfoFieldsItem} from '../items/StudiengangInfoItem';
import { RegistrationFieldsItem, RegistrationErrorsItem } from '../items/RegistrationItem';
import { SaveErrorRequest } from '../component/SaveErrorRequest';
import { InputFieldText } from '../component/InputFieldText';
import { InputFieldSelect } from '../component/InputFieldSelect';
import {HeaderStudiengangInfoField } from '../component/HeaderStudiengangInfo';
import { getaccesToken, getNationList, submitRegistration, getcheckfristzeit, getStudiengangInfo } from '../DataAccess';
import { isUndefined } from 'util';
import { HeaderLogo } from '../component/HeaderLogo'
import { RouteComponentProps } from "react-router";
import { validateEmail, anredeList } from '../Helper';
import Footer from '../component/footer'

interface State {
    fields:
    {
        registration: RegistrationFieldsItem,
        studienganginfo : StudiengangsInfoFieldsItem
    },
    errors:
    {
        registration: RegistrationErrorsItem,
        submiterror: boolean
    },
    disabled: boolean, submitted: boolean, accessToken: string, nations: any[], anredelist: any[], stglist: any[]
}

export default class Registration extends React.Component<RouteComponentProps<any>, State> {

    constructor(props: any) {
        super(props)


        this.state = {
            fields: {
                registration: {
                    anrede: '', firstname: '', name: '', birthdate: '', nationalityID: 0, email: '', emailbest: '', stgID: 0, semester: '',ReCaptchaToken: '', geschlecht:''
                },
                studienganginfo: {
                    ID : 0, Studiengangsartbezeichnung:'', Studiengangsbezeichnung:'',Studiengangsortbezeichnung:'',GuideLink:''
                }
            },
            errors: {
                registration: {
                    anrede: '', firstname: '', name: '', birthdate: '', nationalityID: '', email: '', emailbest: '',
                    anredetext: "Please select your salutation!",
                    nametext: "Please fill your Surname!",
                    firstnametext: "Please fill your First Name!",
                    birthdatetext: "Please fill your birthdate!",
                    nationalityIDtext: "Please select your nationality!",
                    emailtext: "Please fill your  Email field",
                    emailvalidtext: "Email is not valid!",
                    emailbesttext: "Please fill your Email confirmation!",
                    emailbestvalidtext: "Email confirmation is not equals to Email!"

                },
                submiterror: false
            }, disabled: false, submitted: false, accessToken: '-1', nations: [], anredelist: anredeList, stglist: []
        };
        this.fetchdata();
    }

    getStgID(): string {
        let search = this.getUrlParams();
        return search.get("programmeid") || "";
    }

    getSemester(): string {
        let search = this.getUrlParams();
        return search.get("semester") || "";
    }

    getAppGuid(): string {
        let search = this.getUrlParams();
        return search.get("applicationguid") || "";
    }

    getUrlParams(): URLSearchParams {
        if (!this.props.location.search) return new URLSearchParams();
        return new URLSearchParams(this.props.location.search);
    }

    fetchdata = () => {

        let appguid = this.getAppGuid();
        sessionStorage.clear();
        if(appguid !== "")
        {
            sessionStorage.setItem('applicationguid', appguid);
            let data = sessionStorage.getItem('applicationguid');

            this.props.history.push('/application');

            return;
        }

        window.location.assign('https://www.imc.ac.at/en/international/transnational-degree-programmes/overview/');

        let stgID = this.getStgID();
        let semester = this.getSemester();
        if (stgID !== "" && semester !== "") {
            let fields = this.state.fields;
            fields.registration["stgID"] = Number(stgID);
            fields.registration["semester"] = semester;
            this.setState({ fields });

        } else {
            this.props.history.push('/expired');
        }
    }



    checkfristzeit = async (accessToken: string, studiengangID: number, semester: string) => {
        await getcheckfristzeit(accessToken, studiengangID, semester).
            then(res => {
                if (res !== null) {

                    let fristzeititem = JSON.parse(JSON.stringify(res));
                    if (fristzeititem !== null) {
                        if (fristzeititem.IsFristzeitOk as boolean === true) {
                            return true;
                        }
                        else {
                             this.props.history.push('/expired');
                             return false;
                        }
                    } else {
                        this.props.history.push('/error');
                        return false;
                    }
                } else {
                    this.props.history.push('/error');
                    return false;
                }
            }
            )
    }


    fillnationList = async () => {
        await getNationList(this.state.accessToken)
            .then(res => { this.setState({ nations: JSON.parse(JSON.stringify(res)) }) })
    }
   

    selectedDropdown = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let fields = this.state.fields;

        switch (event.target.name) {
            case "anrede": fields.registration["geschlecht"] = event.target.value;
                break;
            case "nationalityID": fields.registration["nationalityID"] = Number(event.target.value);
                break;
            case "stgID": fields.registration["stgID"] = Number(event.target.value);
                break;
        }

        this.setState({ fields });
    }

    clickhandler = async (event: any) => {
        event.preventDefault();
        this.setState({ disabled: true })
        let errors = this.state.errors;
        errors["submiterror"] = false;
        this.setState({ errors });
        let srollToY = this.handleValidation();
        if (srollToY === 0) {

         
        } else {
            window.scrollTo(0, srollToY)
            console.log('validation failed')
            this.setState({ disabled: false })
        }
    }

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        let fields = this.state.fields;

        switch (event.target.name) {
            case "firstname": fields.registration["firstname"] = event.target.value;
                break;
            case "surname": fields.registration["name"] = event.target.value;
                break;
            case "birthdate": fields.registration["birthdate"] = event.target.value;
                break;
            case "email": fields.registration["email"] = event.target.value;
                break;
            case "emailbest": fields.registration["emailbest"] = event.target.value;
                break;

        }

        this.setState({ fields });
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = this.state.errors;
        let formIsValid = true;
        let srollToY = 0;

        if (!fields.registration["geschlecht"]) {
            formIsValid = false;
            srollToY = 500;
            errors.registration["anrede"] = errors.registration["anredetext"];
        } else errors.registration["anrede"] = "";


        if (!fields.registration["birthdate"]) {
            formIsValid = false;
            if(srollToY === 0) srollToY = 500;
            errors.registration["birthdate"] = errors.registration["birthdatetext"];
        } else errors.registration["birthdate"] = "";

        if (!fields.registration["name"]) {
            if(srollToY === 0) srollToY = 700;
            formIsValid = false;
            errors.registration["name"] = errors.registration["nametext"];
        } else errors.registration["name"] = "";


        if (!fields.registration["firstname"]) {
            formIsValid = false;
            if(srollToY === 0) srollToY = 600;
            errors.registration["firstname"] = errors.registration["firstnametext"];
        } else errors.registration["firstname"] = "";

        


        if (!fields.registration["email"]) {
            formIsValid = false;
            if(srollToY === 0) srollToY = 600;
            errors.registration["email"] = errors.registration["emailtext"];
        } else {
            if (!validateEmail(fields.registration["email"])) {
                formIsValid = false;
                if(srollToY === 0) srollToY = 600;
                errors.registration["email"] = errors.registration["emailvalidtext"];
            }
            else errors.registration["email"] = "";
        }

        if (!fields.registration["emailbest"]) {
            formIsValid = false;
            if(srollToY === 0) srollToY = 700;
            errors.registration["emailbest"] = errors.registration["emailbesttext"];
        } else {
            if (!fields.registration["email"] === false && fields.registration["email"] !== fields.registration["emailbest"]) {
                formIsValid = false;
                if(srollToY === 0) srollToY = 700;
                errors.registration["emailbest"] = errors.registration["emailbestvalidtext"];
            }
            else errors.registration["emailbest"] = "";
        }

        this.setState({ errors });
        return srollToY;
    }

    render() {

        return (
            <div id="visual-portal-wrapper" className="clearfix">
                <HeaderLogo />

                <div id="portal-columns" className="row">
                    <div id="portal-columns-content" className="container">
                        <h1>IMC Krems online application system</h1>
                        
                        <p className="description">Welcome to the IMC Krems online application system! Thank you for placing your trust in our university.</p>

                        <p className="discreet">
                        <b>First things first:</b> We only accept applications submitted using the online tool. Please register here to access the online application system for the {this.state.fields.studienganginfo.Studiengangsartbezeichnung} {this.state.fields.studienganginfo.Studiengangsbezeichnung} in {this.state.fields.studienganginfo.Studiengangsortbezeichnung}.<br /><br />
                        After registering you will receive your personalised link by e-mail to start your online application. In order, that the e-mail with your personal access link can be sent properly, please make sure to use a valid and correctly typed e-mail-address for your registration. <br /><br />
                        <b>Good to know:</b> Please note that despite registering you may only submit your online application within the determined application period. 
                        </p>
                       
                        <fieldset style={{ marginTop: "-25px" }}>
                            <legend> Registration for the {this.state.fields.studienganginfo.Studiengangsartbezeichnung} {this.state.fields.studienganginfo.Studiengangsbezeichnung} in {this.state.fields.studienganginfo.Studiengangsortbezeichnung}</legend>

                            <div className="row">

                                <InputFieldSelect Text="Gender" tabindex={1} htmlname="anrede" selectedDropdown={this.selectedDropdown} errorvalue={this.state.errors.registration["anrede"]}
                                    data={this.state.anredelist} value={this.state.fields.registration.geschlecht} required={true} istooltip={true} tooltiptext='Diverse can only be chosen if this sex is clearly stated in your passport.' />

<InputFieldText Type="date" Text="Date of birth" tabindex={4} htmlname="birthdate" value={this.state.fields.registration.birthdate} required={true}
                                    handleInputChange={this.handleInputChange} errorvalue={this.state.errors.registration["birthdate"]} istooltip={false} tooltiptext='' />

                                
                            </div><div className="row">

                            <InputFieldText Type="text" Text="First Name" tabindex={2} htmlname="firstname" value={this.state.fields.registration.firstname} required={true}
                                    handleInputChange={this.handleInputChange} errorvalue={this.state.errors.registration["firstname"]} istooltip={false} tooltiptext='' />

<InputFieldText Type="email" Text="E-mail address" tabindex={5} htmlname="email" value={this.state.fields.registration.email} required={true}
                                    handleInputChange={this.handleInputChange} errorvalue={this.state.errors.registration["email"]} istooltip={false} tooltiptext='' />
                             
                               
                            </div><div className="row">

                            <InputFieldText Type="text" Text="Surname" tabindex={3} htmlname="surname" value={this.state.fields.registration.name} required={true}
                                    handleInputChange={this.handleInputChange} errorvalue={this.state.errors.registration["name"]} istooltip={false} tooltiptext='' />

<InputFieldText Type="email" Text="Please confirm E-mail address" tabindex={6} htmlname="emailbest" value={this.state.fields.registration.emailbest} required={true}
                                    handleInputChange={this.handleInputChange} errorvalue={this.state.errors.registration["emailbest"]} istooltip={false} tooltiptext='' />

                            </div>
                        </fieldset>
                        <div className="form-info">
                            <p className="discreet">Note: All fields marked with an asterisk <span style={{ color: "red" }}>*</span> must be completed!</p>
                        </div>
                        <fieldset>
                            <h4 className="subheadline">Data Protection</h4>
                            <p className="discription">The IMC University of Applied Sciences Krems, Piaristengasse 1, 3500 Krems, 
                            <a itemProp='email' className="value" href="mailto:datenschutz@fh‐krems.ac.at" target="_blank">datenschutz@fh‐krems.ac.at</a>, 
                            processes the personal data that you have submitted in your online application. 
                            This processing is based on the fulfilment of the pre-contractual legal relationship in accordance with Art 6 para 1 lit b GDPR and is carried out for the purpose of processing your application.
                            <br></br><br></br>
                            Please find the details regarding the processing of your data in the current <a href="https://www.fh-krems.ac.at/fileadmin/public/downloads/rechtliches/information-on-data-protection.pdf" target="_blank">data protection declaration</a>, 
                            in particular under "Student applicant data for online application".<br></br><br></br>
                            The data you provided in the online application will be used for marketing purposes. 
                            This processing is based on the legitimate interest of IMC University of Applied Sciences Krems in accordance with Art 6 para. 1 lit f GDPR in order to safeguard the competitiveness of the company. 
                            You have the right to object to the use of the data provided for the purpose of marketing at <a itemProp="email" className="value" href="mailto:datenschutz@fh‐krems.ac.at" target="_blank">datenschutz@fh‐krems.ac.at</a> 
                            and so prevent further use of this data for marketing purposes. 
                            Please find the details regarding the processing of your data in the current <a href="https://www.fh-krems.ac.at/fileadmin/public/downloads/rechtliches/information-on-data-protection.pdf" target="_blank">data protection declaration</a>, 
                            in particular under "Other rights of data subjects".</p>
                        </fieldset>
                        <div className="formControls clearfix" style={{marginTop:10}}>

                            </div>
                        <div className="formControls clearfix">
                            {this.state.errors["submiterror"] && <SaveErrorRequest />}
                           <button type="submit" className="button orange right" disabled={this.state.disabled} onClick={this.clickhandler}>Register</button><br /><br />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
