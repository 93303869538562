import * as React from "react";

export default class RequiredFieldDescription  extends React.Component<{}, {}>{
   
    render() {
        
            return (
                
                <div className="form-info">
                                <p className="discreet">Note: All fields marked with an asterisk <span style={{ color: "red" }}>*</span> must be completed!</p>
                            </div>
            );
       
    }
}