import * as React from "react";
import {StudiengangsInfoFieldsItem} from "../items/StudiengangInfoItem"
import {HeaderStudiengangInfoField } from '../component/HeaderStudiengangInfo'
import { RouteComponentProps } from "react-router";
import { getaccesToken, getBewerberGuid, getDownloadDokumentGuid, getDocumentGuid, getMotivationGuid, SaveBewerberMotivation, SaveBewerberMotivationOneField } from '../DataAccess';
import { isUndefined } from 'util';
import { HeaderLogoIntern } from '../component/HeaderLogo'
import { LoadingAnimation } from '../component/loadingComponent'
import { MotivationField } from '../component/MotivationField'
import MotivationFieldsItem from "../items/MotivationFieldItem";
import { IMotivationItem } from "../interface/Interface";
import Footer from '../component/footer'
import { SaveErrorRequest } from '../component/SaveErrorRequest'
import fileDownload from "js-file-download"


interface State {
    studienganginfo : StudiengangsInfoFieldsItem, isessay: boolean, strTitle : string
    Motivation: MotivationFieldsItem, submiterror: boolean
    SaveMotivation : MotivationFieldsItem
    disabled: boolean, submitted: boolean, accessToken: string, ready: boolean, timeout: boolean, error: string, autofocusIndex:number
}

export default class letterofmotivation extends React.Component<RouteComponentProps<any>, State> {

    constructor(props: any) {
        super(props)
        this.state = {
            Motivation:
            {
                bewerberID: 0, stgGuid: '', motivationlist:
                    [{
                        bezeichnung_eng: '', maxValue: 0, ref_studiengaenge_BewerbungMotivationschreibenID: 0,
                        reihenfolge: 0, tpp_ref_studiengaenge_bewerberID: 0, tpp_ref_studiengaenge_bewerber_motivationschreibenID: 0,
                        value: '', isessay:false, essayvalue:'', essayFrage:''
                    }]
            }, 
            SaveMotivation:
            {
                bewerberID: 0, stgGuid: '', motivationlist:
                    [{
                        bezeichnung_eng: '', maxValue: 0, ref_studiengaenge_BewerbungMotivationschreibenID: 0,
                        reihenfolge: 0, tpp_ref_studiengaenge_bewerberID: 0, tpp_ref_studiengaenge_bewerber_motivationschreibenID: 0,
                        value: '', isessay:false, essayvalue:'', essayFrage:''
                    }]
            },
            studienganginfo: {
              ID : 0, Studiengangsartbezeichnung:'', Studiengangsbezeichnung:'',Studiengangsortbezeichnung:'',GuideLink:''
            }, isessay: false, submiterror: false, strTitle:'',
            disabled: false, submitted: false, accessToken: '-1', ready: true, timeout: false, error: '', autofocusIndex:0
        }
        setTimeout(() => this.setState({ timeout: true }), 30000);
        let guid = this.getGuid();
        if ((guid === "" || guid === null)) {
            this.props.history.push('/');
        }
        this.checkaccesstoken(guid);
    }

    locationError() {
        this.props.history.push({
            pathname: '/error'
        })
    }

    getGuid(): string {
        let data = sessionStorage.getItem('applicationguid');
        return data || "";
    }

    getUrlParams(): URLSearchParams {
        if (!this.props.location.search) return new URLSearchParams();
        return new URLSearchParams(this.props.location.search);
    }

    checkaccesstoken = async (guid: string) => {

        if (this.state.accessToken === "-1" || this.state.accessToken === null) {
            await getaccesToken().
                then(res => {
                    if (res !== null && res !== isUndefined && typeof res === "string") {
                        this.setState({ accessToken: res.toString() })
                        this.checkGuid(guid);
                    }
                })
        } else await this.checkGuid(guid);
    }

    checkGuid = async (guid: string) => {

        
        await getMotivationGuid(this.state.accessToken, guid)
            .then(res => {
                console.log(res);
                let TTPBewerberItem = JSON.parse(JSON.stringify(res));
                let Motivation = this.state.Motivation;
                let studienganginfo = this.state.studienganginfo;
                let isessay = this.state.isessay;
                let strTitle = this.state.strTitle;
                Motivation.stgGuid = TTPBewerberItem.StrGuid;

                if (Motivation.stgGuid !== guid) {
                    this.props.history.push({
                        pathname: '/',
                        state: { accessToken: this.state.accessToken }
                    })
                } else {
                    studienganginfo.ID = TTPBewerberItem.StudiengangID as number;
                    studienganginfo.Studiengangsartbezeichnung = TTPBewerberItem.Studiengangsartbezeichnung;
                    studienganginfo.Studiengangsbezeichnung = TTPBewerberItem.Studiengangsbezeichnung;
                    studienganginfo.Studiengangsortbezeichnung = TTPBewerberItem.Studiengangsortbezeichnung;
                    isessay = TTPBewerberItem.isessay as boolean;
                    strTitle = (isessay)? 'Statement of motivation and essay' : 'Statement of motivation';
                    if (TTPBewerberItem.MotivationschreibenList !== null) {
                        let liste = TTPBewerberItem.MotivationschreibenList as [];
                        if (liste.length > 0) {
                            var tmplU = [] as IMotivationItem[];
                            for (let u = 0; u < liste.length; u++) { tmplU.push(liste[u] as IMotivationItem); }
                            Motivation.motivationlist = tmplU;
                        }
                    }

                    this.setState({ Motivation, studienganginfo, ready: true , isessay, strTitle});
                }

            })
    }


    handleInputChange = async (event: React.ChangeEvent<HTMLTextAreaElement>, ref_studiengaenge_BewerbungMotivationschreibenID: number) => {

        let arr = this.state.Motivation;
        var _index = arr.motivationlist.findIndex(value => value.ref_studiengaenge_BewerbungMotivationschreibenID === ref_studiengaenge_BewerbungMotivationschreibenID);
        const _l = arr.motivationlist[_index];

        if(_l.value === null || _l.value === '')
        {
            let lettercount = event.target.value === null ? 0 : event.target.value.replace(/\n /, "").replace(/\r/, "").length;
            if(lettercount > _l.maxValue)
            {
                var substring =  event.target.value.substring(0, _l.maxValue);
                _l.value = substring;

            }else _l.value = event.target.value;
        }else{
            let lettercount = event.target.value === null ? 0 : event.target.value.replace(/\n /, "").replace(/\r/, "").length;
            if(lettercount <= _l.maxValue)
            {
                _l.value = event.target.value;
            }
        }

        await this.setState({ Motivation: arr });
    }

    handleInputChangeForEssay = async (event: React.ChangeEvent<HTMLTextAreaElement>, ref_studiengaenge_BewerbungMotivationschreibenID: number) => {

        let arr = this.state.Motivation;
        var _index = arr.motivationlist.findIndex(value => value.ref_studiengaenge_BewerbungMotivationschreibenID === ref_studiengaenge_BewerbungMotivationschreibenID);
        const _l = arr.motivationlist[_index];

        _l.essayvalue = event.target.value;

        await this.setState({ Motivation: arr });
    }

    clickBackhandler = async (event: any) => {
        event.preventDefault();
        this.setState({ disabled: true })
        this.props.history.push('/documents');
        // this.props.history.push({
        //     pathname: '/documents',
        //     search: 'applicationguid=' + this.state.Motivation.stgGuid,
        //     state: { accessToken: this.state.accessToken }
        // })
    }

    handleValidation() {

        let formIsValid = true;
        let errors = this.state.error;
        errors = '';
        for (let u = 0; u < this.state.Motivation.motivationlist.length; u++) {
            let value = this.state.Motivation.motivationlist[u];
            if (!value.value || value.value === '') {
                formIsValid = false;
                errors = 'All input fields of the letter of motivation are compulsory fields. Please complete the predefined the fields.'
                break;
            }
        }

        this.setState({ error: errors })

        return formIsValid;
    }

    clickSubmithandler = async (event: any) => {

        event.preventDefault();
        this.setState({ disabled: true, submiterror: false, ready:false })



        if (this.handleValidation()) {

            if (this.state.accessToken !== "-1" && this.state.accessToken !== null) {

                let json = JSON.stringify(this.state.Motivation);
                const response = await SaveBewerberMotivation(this.state.accessToken, json);

                console.log(response);
                if (response === 200) {
                    this.props.history.push('/documents');
                    // this.props.history.push({
                    //     pathname: '/documents',
                    //     search: 'applicationguid=' + this.state.Motivation.stgGuid,
                    //     state: { accessToken: this.state.accessToken }
                    // })
                } else {
                    this.setState({ disabled: false, submiterror: true, ready:true });
                }

            }
        } else {
            console.log('validation failed')
            this.setState({ disabled: false, ready:true })
        }
    }

    ClickSaveOneField = async (event:any,ref_studiengaenge_BewerbungMotivationschreibenID: number) => { 
        
        event.preventDefault();

        if (this.state.accessToken !== "-1" && this.state.accessToken !== null) {

            let arr = this.state.Motivation;
            var _index = arr.motivationlist.findIndex(value => value.ref_studiengaenge_BewerbungMotivationschreibenID === ref_studiengaenge_BewerbungMotivationschreibenID);
            const _l = arr.motivationlist[_index];


            var tmplU = [] as IMotivationItem[];
            tmplU.push(_l as IMotivationItem); 
           
            let Motivationsave = this.state.SaveMotivation;
            Motivationsave.stgGuid = arr.stgGuid;
            Motivationsave.motivationlist = tmplU;

            let json = JSON.stringify(Motivationsave);
            const response = await SaveBewerberMotivationOneField(this.state.accessToken, json);

            console.log(response);
            if (response === 200) {
             await this.setState({ autofocusIndex: _index+1 });
            } else {
                await this.setState({ autofocusIndex: _index });
            }
        }
    }

    render() {
        if (this.state.timeout === true && !this.state.ready) this.locationError();
        if (!this.state.ready)
            return <LoadingAnimation />;
        return (
            <div id="visual-portal-wrapper" className="clearfix">
                <HeaderLogoIntern />
                <div id="portal-columns" className="row">
                    <div id="portal-columns-content" className="container">
                        <h1>IMC Krems online application system</h1>
                        <HeaderStudiengangInfoField StudiengangsArtbezeichnung={this.state.studienganginfo.Studiengangsartbezeichnung} StudiengangsOrtbezeichnung={this.state.studienganginfo.Studiengangsortbezeichnung} Studiengangsbezeichnung={this.state.studienganginfo.Studiengangsbezeichnung} 
                    ></HeaderStudiengangInfoField>
                        <fieldset id="Fieldset_P" >
                        <legend style={{paddingTop: '40px'}}>{this.state.strTitle}</legend>
                        {this.state.isessay === true && 
                            <p className="description" >
                                Please write your statement of motivation and the essay <b>in English language</b> in accordance with the requirements listed below and mind the character limit per text box.
                            </p>}
                        {this.state.isessay === false && 
                            <p className="description" >
                                Please write your statement of motivation <b>in English language</b> in accordance with the requirements listed below and mind the character limit per text box.
                            </p>}
                        </fieldset>
                        <div>
                            <div id="div_start" >
                                {this.state.Motivation.motivationlist.map((value, index) => <MotivationField autofocusIndex={this.state.autofocusIndex} 
                                ClickSaveOneField={this.ClickSaveOneField} 
                                index={index} key={index} 
                                clientid={value.reihenfolge} 
                                isessay={value.isessay} 
                                essayvalue={value.essayvalue} 
                                essayfrage={value.essayFrage}
                                ref_studiengaenge_BewerbungMotivationschreibenID={value.ref_studiengaenge_BewerbungMotivationschreibenID} 
                                Text={value.bezeichnung_eng} 
                                value={value.value} required={true} maxlenght={value.maxValue} 
                                handleInputChange={this.handleInputChange}  
                                handleInputChangeForEssay={this.handleInputChangeForEssay} 
                                lettercount={value.value === null ? 0 : value.value.replace(/\n /, "").replace(/\r/, "").length}></MotivationField>)}
                            </div>

                            <div className="formControls clearfix">
                                {this.state.submiterror && <SaveErrorRequest />}
                                <fieldset style={{paddingTop: '20px'}}>
                                    <ul className="form">
                                        <li>
                                            <button type="button" id="ButtonBack" className="button blue left w-100" disabled={this.state.disabled} onClick={this.clickBackhandler} >Back</button>
                                        </li>
                                        <li>
                                            <button type="button" id="_ButtonrightLater" className="button blue left w-100" disabled={this.state.disabled} >Save and continue later</button>
                                        </li>
                                        <li className="w-100">
                                        <span style={{ color: "orange", float: "left" }}>{this.state.error}</span>
                                            <button type="button" id="_Buttonright" className="button orangemot right" disabled={this.state.disabled} onClick={this.clickSubmithandler} >Save and close</button>
                                        </li>
                                    </ul>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}