import * as React from 'react'
import { Link } from "react-router-dom";
import { ImageIcon} from './ImageIcon'


interface LinkProps {
    titel : string
    pathname : string
    guid : string,
    accessToken : string
    BooleanValuePhase : boolean
    disabled : boolean
}


export  class RowCategoryStatusField extends React.Component<LinkProps, {}>{
    constructor(props: LinkProps) {
        super(props);
        //className='disabled-link'
        //className="button blue left"
    };
    
    render() {
        let str_classname = "button blue left";
        if(this.props.disabled) str_classname="button blue left disabled-link";
        return(

            <tr>
            <td><Link className={str_classname}  to={this.props.pathname} >{this.props.titel}</Link></td>
                <td className="align_center statusIcon">
                <ImageIcon BooleanValue={this.props.BooleanValuePhase}></ImageIcon>
                </td>
        </tr>

        );
    }
}