import * as React from 'react'
import { Link } from "react-router-dom";
import { ImageIcon} from './ImageIcon'


interface InfoProps {
    Studiengangsbezeichnung : string
    StudiengangsArtbezeichnung : string
    StudiengangsOrtbezeichnung : string
}


export  class HeaderStudiengangInfoField extends React.Component<InfoProps, {}>{
    constructor(props: InfoProps) {
        super(props);
        //className='disabled-link'
        //className="button blue left"
    };
    
    render() {
        return(
            <p className="description">Application for the {this.props.StudiengangsArtbezeichnung} {this.props.Studiengangsbezeichnung} in {this.props.StudiengangsOrtbezeichnung}</p>
        );
    }
}