import * as React from 'react'
import icontrue from '../images/icon_true.png';
import iconfalse from '../images/icon_error.png';


interface ImageProps {

    BooleanValue : boolean
}


export  class ImageIcon extends React.Component<ImageProps, {}>{
    constructor(props: ImageProps) {
        super(props);
    };


    render() {
        return(<div>{this.props.BooleanValue && <img src={icontrue} alt="OK" /> }{this.props.BooleanValue === false && <img src={iconfalse} alt="Not OK" /> }</div> 
        );
    }
}
