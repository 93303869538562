import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Registration from './Path/registration'
import PersonalData from './Path/personaldata'
import Landingpage from './Path/langingpage'
import Successfull from './Path/successfull'
import Qualification from './Path/qualification'
import Documents from './Path/documents'
import Subsequent from './Path/subsequent'
import ErrorConnection from './Path/ErrorConncection'
import FotoUpload from './Path/fotoupload'
import lettermotivation from './Path/lettermotivation'
import ExpiredInfo from './Path/expired'
import CompletedInfo from './Path/completed'
import SuccessfullApplied from './Path/successfullapplied'
import * as serviceWorker from './serviceWorker';
import { Route, BrowserRouter } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.css";
import '@progress/kendo-theme-bootstrap/dist/all.css';
import './css/bewerberonline.css';

ReactDOM.render(
   <BrowserRouter>
      <Route exact path="/" component={Registration} />
      <Route path="/registration" component={Registration} />
      <Route path="/application" component={Landingpage} />
      <Route path="/successfull" component={Successfull} />
      <Route path="/personalData" component={PersonalData} />
      <Route path="/qualifications" component={Qualification} />
      <Route path="/documents" component={Documents} />
      <Route path="/subsequent" component={Subsequent} />
      <Route path="/error" component={ErrorConnection} />
      <Route path="/fotoupload" component={FotoUpload} />
      <Route path="/lettermotivation" component={lettermotivation} />
      <Route path="/expired" component={ExpiredInfo} />
      <Route path="/completed" component={CompletedInfo} />
      <Route path="/successfullapplied" component={SuccessfullApplied} />
   </BrowserRouter>, document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();