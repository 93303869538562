import * as React from "react";

export default class Footer extends React.Component<{}, {}>{

    render() {

        return (
            <footer id="portal-footer">
                <div id="portal-footer-content" className="container clearfix">
                    <div className="vcard g240">
                        <dl className="adr" id="contact">
                            <dt>Contact</dt>
                            <dd className="fn org organization-name"><abbr className="geo" title="48.412863;15.6002"><span>IMC Krems admission team<br></br>IMC University of Applied Sciences</span></abbr></dd>
                            <dd><span className="street-address">Piaristengasse 1</span> - <span className="postal-code">3500</span> <span className="locality">Krems</span> - <span className="country-name">Austria</span> - <span className="region">Europe</span></dd>
                            <dd>E: <a itemProp="email" rel='noreferrer' className="value" href="mailto:admission@imc.ac.at" target="_blank">admission@imc.ac.at</a></dd>
                            <dd>I: <a itemProp="website" rel='noreferrer' className="value" href="https://www.imc.ac.at" target="_blank">www.imc.ac.at</a></dd>
                        </dl>
                    </div>
                </div>
            </footer>
        );

    }
}