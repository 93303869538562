import * as React from 'react'
import fotoinfo from '../images/photoinfo.png';

interface TooltipProps {
    title: string
}

export class TooltipContentFotoUpload extends React.Component<TooltipProps, {}> {
    constructor(props: TooltipProps) {
        super(props);
    }

    render() {
        return (
            <div  style={{ maxWidth: '1000px'  }}>
                <img src={fotoinfo} alt="Information about photoupload" />
            </div>

        );
    }
}