import * as React from "react";
import ReactLoading from 'react-loading';

export class LoadingAnimation  extends React.Component<{}, {}>{
   
    render() {
        return (
            <div id="main-loader">
                <ReactLoading type="spin" color="rgba(47,72,102,1)" width={100} height={100} />
            </div>
        );
    }
}