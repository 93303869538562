import * as React from "react";
import {StudiengangsInfoFieldsItem} from "../items/StudiengangInfoItem"
import {HeaderStudiengangInfoField } from '../component/HeaderStudiengangInfo'
import DocumentFieldItem from "../items/DocumentFieldItem"
import { RouteComponentProps } from "react-router";
import { getaccesToken, getDownloadDokumentGuid, getDocumentGuid, getDownloadFotoGuid, SaveBewerberDocument } from '../DataAccess';
import { isUndefined } from 'util';
import { HeaderLogoIntern } from '../component/HeaderLogo'
import { IDokument, IUploadDocument } from "../interface/Interface";
import { FileUploader } from '../component/FileUploader'
import { LoadingAnimation } from '../component/loadingComponent'
import fileDownload from "js-file-download"
import { OptDocumentTable } from "../component/optionaleDocuments/optDocumentTable";
import Footer from '../component/footer'
import { SaveErrorRequest } from '../component/SaveErrorRequest'

interface State {
  Document: DocumentFieldItem,
  
  studienganginfo : StudiengangsInfoFieldsItem,
  errors:
  {
    errorValuetext: string
    errortext: string
    submiterror: boolean
  },
  disabled: boolean, submitted: boolean, accessToken: string, ready: boolean, timeout: boolean
}

export default class Documents extends React.Component<RouteComponentProps<any>, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      errors: { errorValuetext: 'All mandatory documents must be uploaded!', errortext: '', submiterror: false },
      Document: {
        Phase3Isgespeichert: false,
        Phase3fertig: false,
        bewerberID: 0,
        stgGuid: '',
        fotodokument: { bewerbungsunterlageID: 0, clientId: 0, bewerbungsunterlagebezeichnung: '', typ: '', isoptional: true, dokumentid: 0, beschreibung: '', filename: '', isessay:false },
        pflichtdocumentlist: [{ clientId: 0, bewerbungsunterlageID: 0, bewerbungsunterlagebezeichnung: '', dokumentid: 0, isoptional: false, typ: '', beschreibung: '', filename: '', isessay:false }],
        optionaldocumentlist: [{ clientId: 0, bewerbungsunterlageID: 0, bewerbungsunterlagebezeichnung: '', dokumentid: 0, isoptional: true, typ: '', beschreibung: '', filename: '', isessay:false }]
        }, 
        studienganginfo: {
          ID : 0, Studiengangsartbezeichnung:'', Studiengangsbezeichnung:'',Studiengangsortbezeichnung:'', GuideLink:''
        },
      disabled: false, submitted: false, accessToken: '-1', ready: false, timeout: false
    }
    setTimeout(() => this.setState({ timeout: true }), 50000);
    let guid = this.getGuid();
    if ((guid === "")) {
      this.props.history.push('/');
    }
    this.checkaccesstoken(guid);
  }

  locationError() {
    this.props.history.push({
      pathname: '/error'
    })
  }

  handleValidation() {
    let fields = this.state.Document;
    let errors = this.state.errors;
    errors.errortext = '';
    let formIsValid = true;

    // dokumente checken
    for (let u = 0; u < fields.pflichtdocumentlist.length; u++) {
      let value = fields.pflichtdocumentlist[u];
      if (!value.isoptional && value.dokumentid === 0) {
        formIsValid = false;
        errors.errortext = errors.errorValuetext;
        break;
      }
    }

    return formIsValid;
  }

  clickSubmithandler = async (event: any) => {

    event.preventDefault();
    this.setState({ disabled: true })
    let errors = this.state.errors;
    errors["submiterror"] = false;
    this.setState({ errors });

    if (this.handleValidation()) {

      if (this.state.accessToken !== "-1" && this.state.accessToken !== null) {

        this.state.Document.Phase3fertig = true;
       
        let json = JSON.stringify(this.state.Document);
        
        const response = await SaveBewerberDocument(this.state.accessToken, json);

        if (response === 200) {
          this.props.history.push('/application');

        } else {
          this.setState({ disabled: false })
          let errors = this.state.errors;
          errors["submiterror"] = true;
          this.setState({ errors });
        }
      }
    } else {
      window.scrollTo(0, 800)
      console.log('validation failed')
      this.setState({ disabled: false })
    }
  }

  getGuid(): string {
    let data = sessionStorage.getItem('applicationguid');
        return data || "";
  }

  getUrlParams(): URLSearchParams {
    if (!this.props.location.search) return new URLSearchParams();
    return new URLSearchParams(this.props.location.search);
  }

  checkaccesstoken = async (guid: string) => {

    if (this.state.accessToken === "-1") {
      await getaccesToken().then(res => {
          if (res !== null && res !== isUndefined && typeof res === "string") {
            this.setState({ accessToken: res.toString() })
            this.checkGuid(guid);
          }
        })
    } else await this.checkGuid(guid);
  }

  checkGuid = async (guid: string) => {


    await getDocumentGuid(this.state.accessToken, guid)
      .then(res => {

        let TTPBewerberItem = JSON.parse(JSON.stringify(res));
        let Document = this.state.Document;
        let studienganginfo = this.state.studienganginfo;
        Document.stgGuid = TTPBewerberItem.StrGuid;

        studienganginfo.ID = TTPBewerberItem.StudiengangID as number;
        studienganginfo.Studiengangsartbezeichnung = TTPBewerberItem.Studiengangsartbezeichnung;
        studienganginfo.Studiengangsbezeichnung = TTPBewerberItem.Studiengangsbezeichnung;
        studienganginfo.Studiengangsortbezeichnung = TTPBewerberItem.Studiengangsortbezeichnung;
        studienganginfo.GuideLink = TTPBewerberItem.GuideLink;

        if (Document.stgGuid !== guid) {
          this.props.history.push({
            pathname: '/',
            state: { accessToken: this.state.accessToken }
          })
        } else {

          Document.fotodokument = TTPBewerberItem.fotodokument as IDokument;
          Document.fotodokument.typ = 'foto';

          if (TTPBewerberItem.pflichtdocumentlist !== null) {
            let liste = TTPBewerberItem.pflichtdocumentlist as [];
            if (liste.length > 0) {
              var tmplU = [] as IDokument[];
              for (let u = 0; u < liste.length; u++) { tmplU.push(liste[u] as IDokument); }
              Document.pflichtdocumentlist = tmplU;
            }
          }

          if (TTPBewerberItem.optionaldocumentlist !== null) {
            let liste = TTPBewerberItem.optionaldocumentlist as [];
            if (liste.length > 0) {
              var tmplU_opt = [] as IDokument[];
              for (let u = 0; u < liste.length; u++) { tmplU_opt.push(liste[u] as IDokument); }
              Document.optionaldocumentlist = tmplU_opt;
            }

            Document.optionaldocumentlist.map((value, index) => Document.optionaldocumentlist[index].clientId = index)

          }

          Document.Phase3fertig = TTPBewerberItem.Phase3;
          this.setState({ Document, studienganginfo, ready: true });
        }
      })

    // getaccesToken().
    //   then(res => {
    //     if (res !== null && res !== isUndefined && typeof res === "string") {
    //       this.setState({ accessToken: res.toString() })
    //     }


    //     // //var url2 = process.env.REACT_APP_DATA_API + "api/TPPBewerber/TPPBewerber/GetTPPDownloadDokumentGUID?id=432&guid=b95e6090-142f-4aeb-bcc7-616cbeb1df9c";
    //     // var url2 = "http://localhost:44302/api/TPPBewerber/GetTPPDownloadDokumentGUID?id=882627&guid=b95e6090-142f-4aeb-bcc7-616cbeb1df9c";
    //     // axios.get(url2,
    //     // { headers: getOAuthAuthorizationHeader(this.state.accessToken) })
    //     // .then(response => {
    //     //   let TTPBewerberItem = JSON.parse(JSON.stringify(response));
    //     //   console.log(TTPBewerberItem.data.bewerber.downloaddocument);
    //     //   const encoded = new TextEncoder().encode(TTPBewerberItem.data.bewerber.downloaddocument.dokumentbase64) // is Uint8Array
    //     //   const buf = encoded.buffer
    //     //   console.log("base64 to buf: " + new Uint8Array(buf));


    //     //   var dataUrl = "data:application/octet-binary;base64," + TTPBewerberItem.data.bewerber.downloaddocument.dokumentbase64;

    //     //   fetch(dataUrl)
    //     //     .then(res => res.arrayBuffer())
    //     //     .then(buffer => {
    //     //       //fileDownload(new Uint8Array(buffer), 'vignette.jpg');
    //     //       console.log("base64 to buffer: " + new Uint8Array(buffer));
    //     //     })
    //     //   // let byteArray = Base64Binary.decodeArrayBuffer(base64_string); 
    //     //   // let blob = new Blob([response.data.bewerber.downloaddocument.dokument], {type: 'application/octet-stream'})
    //     //    //fileDownload(buf, 'vignette.jpg');
    //     //   // let blob = new Blob([response.data], {type: 'application/octet-stream'})
    //     //   //   let ref = this.state.ref
    //     //   //   ref = React.createRef()
    //     //   //   ref.current.href = URL.createObjectURL(blob)
    //     //   //   ref.current.download = 'data.csv'
    //     //   //   ref.current.click()
    //     //   // return response.data
    //     // }).catch(response => {
    //     //   console.error(response.message)
    //     //   console.error("Error getDownloadDokumentGuid")
    //     // })




    //   })
  }

  handleupload = async (event: IUploadDocument) => {

    if (event.clientid === 0) {
      let fotodokument = this.state.Document;
      fotodokument.fotodokument.dokumentid = event.dokumentid;
      fotodokument.fotodokument.filename = event.filename;
      await this.setState({ Document: fotodokument });

    } else {

      let dokument = this.state.Document;
      let arr = dokument.pflichtdocumentlist;
      var _index = arr.findIndex(value => value.bewerbungsunterlageID === event.bewerbungsunterlageID);
      const _l = arr[_index];

      _l.dokumentid = event.dokumentid;
      _l.filename = event.filename;
      await this.setState({ Document: dokument });
    }
  }

  handledownload = async (event: number) => {
    await getDownloadDokumentGuid(this.state.accessToken, this.state.Document.stgGuid, event).then(
      res => {

        let TTPBewerberItem = JSON.parse(JSON.stringify(res));

        if (TTPBewerberItem === null || TTPBewerberItem.downloaddocument === null) return;
        var dataUrl = "data:application/octet-binary;base64," + TTPBewerberItem.downloaddocument.document;

        fetch(dataUrl)
          .then(res => res.arrayBuffer())
          .then(buffer => {
            fileDownload(new Uint8Array(buffer), TTPBewerberItem.downloaddocument.filename);

          })

      }
    )
  }

  handleFotodownload = async (event: number) => {
    await getDownloadFotoGuid(this.state.accessToken, this.state.Document.stgGuid, event).then(
      res => {

        let TTPBewerberItem = JSON.parse(JSON.stringify(res));

        if (TTPBewerberItem === null || TTPBewerberItem.downloaddocument === null) return;
        var dataUrl = "data:application/octet-binary;base64," + TTPBewerberItem.downloaddocument.document;

        fetch(dataUrl)
          .then(res => res.arrayBuffer())
          .then(buffer => {
            fileDownload(new Uint8Array(buffer), TTPBewerberItem.downloaddocument.filename);

          })

      }
    )
  }

  handleRedirectFotoUpload = (event: number) => {
    this.props.history.push('/fotoupload');
    // this.props.history.push({
    //   pathname: '/fotoupload',
    //   search: 'applicationguid=' + this.state.Document.stgGuid,
    //   state: { accessToken: this.state.accessToken }
    // })
  }

  handleRedirectMotivationschreiben = (event: number) => {
    this.props.history.push('/lettermotivation');
  }

  handleOptDokListChange = async (optionaldocumentlist: IDokument[]) => {
    let dokument = this.state.Document;
    dokument.optionaldocumentlist = optionaldocumentlist;

    await this.setState({ Document: dokument });

  }

  clickBackhandler = async (event: any) => {
    event.preventDefault();
    this.setState({ disabled: true })
    this.props.history.push('/qualifications');
    // this.props.history.push({
    //   pathname: '/qualifications',
    //   search: 'applicationguid=' + this.state.Document.stgGuid,
    //   state: { accessToken: this.state.accessToken }
    // })
  }

  clickSavehandler = async (event: any) => {
    event.preventDefault();
    this.setState({ disabled: true })
    let errors = this.state.errors;
    errors["submiterror"] = false;
    this.setState({ errors });


    if (this.state.accessToken !== "-1" && this.state.accessToken !== null) {

      this.state.Document.Phase3fertig = false;
      let json = JSON.stringify(this.state.Document);
      console.log(json);
      const response = await SaveBewerberDocument(this.state.accessToken, json);

      if (response === 200) {
        this.props.history.push('/application');
        // this.props.history.push({
        //   pathname: '/application',
        //   search: 'applicationguid=' + this.state.Document.stgGuid,
        //   state: { accessToken: this.state.accessToken }
        // })
      } else {
        this.setState({ disabled: false })
        let errors = this.state.errors;
        errors["submiterror"] = true;
        this.setState({ errors });
      }
    }

  }

  render() {
    if (this.state.timeout === true && !this.state.ready)
      this.locationError();
    if (!this.state.ready)
      return <LoadingAnimation />;
    return (
      <div id="visual-portal-wrapper" className="clearfix">
        <HeaderLogoIntern />
        <div id="portal-columns" className="row">
          <div id="portal-columns-content" className="container">
            <h1>IMC Krems online application system</h1>
            <HeaderStudiengangInfoField StudiengangsArtbezeichnung={this.state.studienganginfo.Studiengangsartbezeichnung} StudiengangsOrtbezeichnung={this.state.studienganginfo.Studiengangsortbezeichnung} Studiengangsbezeichnung={this.state.studienganginfo.Studiengangsbezeichnung} 
                    ></HeaderStudiengangInfoField>
            <p className="description">Step 3 of 3: Document upload</p>
            <form>
              <fieldset id="Fieldset_P">
                <legend>
                  <span id="Label_Fieldset_P"> My document upload</span>
                </legend>
                <p className="discreet">
                Please upload the documents required for your application here.</p>
                <h4>Important pointers</h4><br></br>
                Upload each document separately. Thus, when scanning multiple page documents, please make sure that all pages are saved in only one digital file. <a href="https://avepdf.com/combine-to-pdf" target="_blank" rel="noreferrer noopener">This tool</a> helps you to merge several individual documents into one pdf.
                Useful explanations can be found in the <a href={this.state.studienganginfo.GuideLink} target="_blank" rel="noreferrer noopener">Guide to applying online</a><br /><br />
                Please use only the following document formats (max. 5 MB per document):
              <ul className="discreet">
                  <li style={{marginTop:"0px"}}>PDF</li>
                  <li style={{marginTop:"0px"}}>JPEG/JPG</li>
                </ul>
                You can replace uploaded documents with a different document until you submit your complete online application.<br /><br />
                <h4>International degrees</h4><br></br>
                All certificates issued outside Austria must be translated into German or English and legalised by a notary or by the Austrian diplomatic representation. Please make sure that the digital file contains a full scan of the original document, a complete scan of the proper translation and the legalisation seal.
              <h3>
                  <span id="PflichtdokumentLabel"> Mandatory documents</span>
                </h3>
                {this.state.errors.errortext!=="" && <span style={{ color: "orange", float: "left", marginBottom:"15px" }}>{this.state.errors.errortext}</span>}
                <ol className="nthList form" >
                  <li className="w-100">
                    <FileUploader isoptional={false} stgguid={this.state.Document.stgGuid} handleUpload={this.handleupload}
                      accesstoken={this.state.accessToken} allowtyp={['jpg', 'jpeg']} handleRedirectFotoUpload={this.handleRedirectFotoUpload}
                      handleRedirectMotivationschreiben={this.handleRedirectMotivationschreiben} handleDownload={this.handledownload}
                      handleFotoDownload={this.handleFotodownload} Text={this.state.Document.fotodokument.bewerbungsunterlagebezeichnung}
                      typ={this.state.Document.fotodokument.typ} htmlname='' filename={this.state.Document.fotodokument.filename}
                      documentid={this.state.Document.fotodokument.dokumentid}
                      bewerbungsunterlageid={this.state.Document.fotodokument.bewerbungsunterlageID} clientid={0} isessay={false}></FileUploader></li>
                  {this.state.Document.pflichtdocumentlist.map((value, index) => <li key={index} className="w-100">
                    <FileUploader isoptional={value.isoptional} stgguid={this.state.Document.stgGuid} handleUpload={this.handleupload}
                      handleRedirectFotoUpload={this.handleRedirectFotoUpload} handleRedirectMotivationschreiben={this.handleRedirectMotivationschreiben}
                      accesstoken={this.state.accessToken} handleFotoDownload={this.handleFotodownload}
                      allowtyp={['jpg', 'jpeg', 'pdf']} handleDownload={this.handledownload} Text={value.bewerbungsunterlagebezeichnung} typ={value.typ}
                      htmlname='' filename={value.filename} documentid={value.dokumentid} bewerbungsunterlageid={value.bewerbungsunterlageID}
                      clientid={index + 1} isessay={value.isessay} ></FileUploader></li>)}
                </ol>
              </fieldset><div><OptDocumentTable handleDownload={this.handledownload} stgguid={this.state.Document.stgGuid} accessToken={this.state.accessToken} optionaldocumentlist={this.state.Document.optionaldocumentlist} onListChange={this.handleOptDokListChange}></OptDocumentTable></div>
              <div className="formControls clearfix">
                {this.state.errors["submiterror"] && <SaveErrorRequest />}
                <fieldset>
                  <ul className="form">
                    <li>
                      <button type="button" id="ButtonBack" className="button blue left w-100" disabled={this.state.disabled} onClick={this.clickBackhandler} >Back</button>
                    </li>
                    <li>
                      <button type="button" id="_ButtonrightLater" className="button blue left w-100" disabled={this.state.disabled} onClick={this.clickSavehandler}>Save and continue later</button>
                    </li>
                    <li className="w-100">
                    {this.state.errors.errortext!=="" && <span style={{ color: "orange", float: "left", marginBottom:"15px" }}>{this.state.errors.errortext}</span>}
                      <button type="button" id="_Buttonright" className="button orangesmall right" disabled={this.state.disabled} onClick={this.clickSubmithandler} >Continue</button>
                    </li>
                  </ul>
                </fieldset>
              </div>
            </form>
          </div>
        </div>
        <Footer></Footer>
      </div >
    );
  }
}