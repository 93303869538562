
import QualificationFieldItem from "../items/QualificationFieldItem"
import QualificationErrorItem from "../items/QualificationErrorItem"
import { StudiengangsInfoFieldsItem } from "../items/StudiengangInfoItem"
import { HeaderStudiengangInfoField } from '../component/HeaderStudiengangInfo'
import { SaveErrorRequest } from '../component/SaveErrorRequest'
import { getaccesToken, getNationList, getQualificationGuid, getSprachenList, getSprachenreferenzenList, SaveBewerberQualification } from '../DataAccess'
import { isUndefined } from 'util'
import { HeaderLogoIntern } from '../component/HeaderLogo'
import Footer from '../component/footer'
import RequiredFieldDescription from '../component/requiredfieldDescription'
import { RouteComponentProps } from "react-router"

import { LoadingAnimation } from '../component/loadingComponent'
import { Component } from 'react'
import * as React from 'react'
import { IHighschool, IUniversity, ISelectLanguageSkill, ITextLanguageSkill, IOptionItems } from '../interface/Interface';

interface State {
    fields:
    {
        Qualification: QualificationFieldItem,
        studienganginfo: StudiengangsInfoFieldsItem
    },
    errors:
    {
        Qualification: QualificationErrorItem,
        submiterror: boolean
    },
    disabled: boolean, submitted: boolean, accessToken: string, nations: any[], ready: boolean, languages: any[], levels: any[], timeout: boolean, scrollToY: number, legalguardianyears: any[], legalguardiannations: any[], legalguardianlevels: any[], legalguardianlevelsaustria: any[], showUhStat1: boolean
}

export default class Qualification extends Component<RouteComponentProps<any>, State> {
    constructor(props: any) {
        super(props)
        this.handleSchoolistChange = this.handleSchoolistChange.bind(this);
        this.handleUniversityListChange = this.handleUniversityListChange.bind(this);
        this.handleLanguageSkillsSelectListChange = this.handleLanguageSkillsSelectListChange.bind(this);
        this.handleULanguageSkillsTextChange = this.handleULanguageSkillsTextChange.bind(this);
        this.state = {
            fields: {
                Qualification: {
                    stgGuid: '', bewerberID: 0, zugangcompletiondate: '', schoollist: [{ clientId: 0, nationID: 0, from: '', to: '', id: 0, school: '' }],
                    universitylist: [{ clientId: 0, nationID: 0, from: '', to: '', id: 0, school: '', subject: '', degree: '' }],
                    languageskillsselect: [{ clientId: 0, id: 0, year: '', visibleyear: false, languageid: 0, levelreferenceId: 0 }],
                    languageskillstext: [{ clientId: 0, id: 0, year: '', visibleyear: false, languagetext: '', levelreferenceId: 0 }],
                    zugangprovidedlater: false, zugangssuingcountry: 0, Phase2Isgespeichert: false, Phase2fertig: false, isMaster: false,
                    masterzugangprovidedlater: false, masterzugangssuingcountry: 0, masterzugangcompletiondate: '', uploadenglischzertifikat: false, providedenglischzertifikat: false,
                    optionId: 0, optiontext: '', optionitems: [{ ID: 0, Title: '', selected: false }], isUhStat1Transmitted: false,
                    legalguardianfathercountryofbirth: 0, legalguardianfathercountryofhighesteducation: 0, legalguardianfatherlevelofhighesteducationaustria: 0, legalguardianfatherlevelofhighesteducationothers: 0,
                    legalguardianmothercountryofbirth: 0, legalguardianmothercountryofhighesteducation: 0, legalguardianmotherlevelofhighesteducationaustria: 0, legalguardianmotherlevelofhighesteducationothers: 0,
                    legalguardianfatheryearofbirth: 0, legalguardianmotheryearofbirth: 0
                },
                studienganginfo: {
                    ID: 0, Studiengangsartbezeichnung: '', Studiengangsbezeichnung: '', Studiengangsortbezeichnung: '', GuideLink: ''
                }
            },
            errors: {
                Qualification: {
                    schoolist: '', zugangcompletiondate: '', zugangssuingcountry: '', languageskilllist: '', unilist: '', optionlist: '',
                    masterzugangcompletiondate: '', masterzugangssuingcountry: '',
                    legalguardianfatheryearofbirth: '', legalguardianfathercountryofbirth: '', legalguardianfathercountryofeducation: '', legalguardianfatherlevelofeducationaustria: '', legalguardianfatherlevelofeducationothers: '',
                    legalguardianmotheryearofbirth: '', legalguardianmothercountryofbirth: '', legalguardianmothercountryofeducation: '', legalguardianmotherlevelofeducationaustria: '', legalguardianmotherlevelofeducationothers: '',
                    schoollisttext: "Please fill in all of the fields of high school education!",
                    zugangcompletiondatetext: "Please enter the date of completion of your higher education entrance qualification (e.g. High School Diploma or A-Levels).",
                    zugangssuingcountrytext: "Please select the country where the graduation documents of your higher education entrance qualification (e.g. High School Diploma or A-Levels) have been issued.",
                    languageskilllisttext: "Please fill out all data fields of your  skill of language!",
                    unilisttext: "Please fill out all data fields of your higher education!",
                    masterzugangcompletiondatetext: "Please enter the date of completion of your qualifying prelimnary studies.",
                    zugangcompletiondatecheckDateTextMA: "The completion date of your higher education entrance qualification you have entered must not be a future date.",
                    zugangcompletiondatecheckDateText: "The completion date of your higher education entrance qualification you have entered must not be a future date. If you have not yet completed your higher education entrance qualification (e.g. High School Diploma or A-Levels), then please activate the checkbox 'to be provided later'.",
                    masterzugangcompletiondatecheckDateText: "The completion date of your qualifying preliminary studies you have entered must not be a future date. If you have not yet completed your bachelor studies or an equivalent undergraduate degree programme, then please activate the checkbox 'to be provided later'. ",
                    masterzugangssuingcountrytext: "Please select the country where the graduation documents of your qualifying preliminary studies have been issued.",
                    optionlisttext: "Please select the option that applies to you regarding the foundation year/preparatory course.",
                    legalguardiancountryofbirthtext: "Please select the country of birth of your legal guardian.",
                    legalguardiancountryofeducationtext: "Please select the country of highest education of your legal guardian.",
                    legalguardianlevelofeducationtext: "Please select the level of highest education of your legal guardian.",
                    legalguardianyearofbirthtext: "Please select the year of birth of your legal guardian."
                },
                submiterror: false
            }, disabled: false, submitted: false, accessToken: '-1', nations: [], ready: false, languages: [], levels: [], timeout: false, scrollToY: 0, legalguardianyears: [], legalguardianlevels: [], legalguardiannations: [], legalguardianlevelsaustria: [], showUhStat1: false
        }

        setTimeout(() => this.setState({ timeout: true }), 30000);
        try {
            let guid = this.getGuid();
            if ((guid === "" || guid === null)) {
                this.props.history.push('/');
            }

            this.checkaccesstoken(guid);
        } catch {
            this.locationError();
        }
    }

    locationError() {
        this.props.history.push({
            pathname: '/error'
        })
    }

    handleSchoolistChange(highschoollist: IHighschool[]) {
        let fields = this.state.fields;
        fields.Qualification.schoollist = highschoollist;
        this.setState({ fields });
    }

    handleUniversityListChange(universitylist: IUniversity[]) {
        let fields = this.state.fields;
        fields.Qualification.universitylist = universitylist;
        this.setState({ fields });
    }

    handleLanguageSkillsSelectListChange(languageskillsselect: ISelectLanguageSkill[]) {
        let fields = this.state.fields;
        fields.Qualification.languageskillsselect = languageskillsselect;
        this.setState({ fields });
    }

    handleULanguageSkillsTextChange(languageskillstext: ITextLanguageSkill[]) {
        let fields = this.state.fields;
        fields.Qualification.languageskillstext = languageskillstext;
        this.setState({ fields });
    }

    getGuid(): string {
        let data = sessionStorage.getItem('applicationguid');
        return data || "";
    }

    getUrlParams(): URLSearchParams {
        if (!this.props.location.search) return new URLSearchParams();
        return new URLSearchParams(this.props.location.search);
    }

    checkaccesstoken = async (guid: string) => {


        if (this.state.accessToken === "-1" || this.state.accessToken === null) {
            await getaccesToken().then(res => {
                if (res !== null && res !== isUndefined && typeof res === "string") {
                    this.setState({ accessToken: res.toString() })
                    this.getnationlist(guid);
                }
            })
        } else await this.getnationlist(guid);
    }

    getnationlist = async (guid: string) => {
        await getNationList(this.state.accessToken).then(res => {
            this.setState({ nations: JSON.parse(JSON.stringify(res)) })
            this.getsprachenlist(guid);
        })
    }

    getsprachenlist = async (guid: string) => {
        await getSprachenList(this.state.accessToken).then(res => {
            this.setState({ languages: JSON.parse(JSON.stringify(res)) })
            this.getsprachenreferenzlist(guid);
        })
    }

    getsprachenreferenzlist = async (guid: string) => {
        await getSprachenreferenzenList(this.state.accessToken).then(res => {
            this.setState({ levels: JSON.parse(JSON.stringify(res)) })
            this.checkGuid(guid);
        })
    }

    checkGuid = async (guid: string) => {

        await getQualificationGuid(this.state.accessToken, guid).then(res => {
            let TTPBewerberItem = JSON.parse(JSON.stringify(res));
            let fields = this.state.fields;

            fields.Qualification.stgGuid = TTPBewerberItem.StrGuid;

            if (fields.Qualification.stgGuid !== guid) {
                this.props.history.push({
                    pathname: '/',
                    state: { accessToken: this.state.accessToken, nations: this.state.nations }
                })
            }

            if (TTPBewerberItem.zugangsdatum !== null) fields.Qualification.zugangcompletiondate = TTPBewerberItem.zugangsdatum.split("T")[0];
            if (TTPBewerberItem.zugangsstaatID !== null) fields.Qualification.zugangssuingcountry = TTPBewerberItem.zugangsstaatID;
            if (TTPBewerberItem.isnachgereicht !== null) fields.Qualification.zugangprovidedlater = TTPBewerberItem.isnachgereicht;

            if (TTPBewerberItem.masterzugangsdatum !== null) fields.Qualification.masterzugangcompletiondate = TTPBewerberItem.masterzugangsdatum.split("T")[0];
            if (TTPBewerberItem.masterzugangsstaatID !== null) fields.Qualification.masterzugangssuingcountry = TTPBewerberItem.masterzugangsstaatID;
            if (TTPBewerberItem.masterisnachgereicht !== null) fields.Qualification.masterzugangprovidedlater = TTPBewerberItem.masterisnachgereicht;

            fields.studienganginfo.ID = TTPBewerberItem.StudiengangID as number;
            fields.studienganginfo.Studiengangsartbezeichnung = TTPBewerberItem.Studiengangsartbezeichnung;
            fields.studienganginfo.Studiengangsbezeichnung = TTPBewerberItem.Studiengangsbezeichnung;
            fields.studienganginfo.Studiengangsortbezeichnung = TTPBewerberItem.Studiengangsortbezeichnung;

            fields.Qualification.isMaster = TTPBewerberItem.isMaster;
            fields.Qualification.uploadenglischzertifikat = TTPBewerberItem.UploadEnglischzertifikat;
            fields.Qualification.providedenglischzertifikat = TTPBewerberItem.ProvidedEnglischzertifikat;
            fields.Qualification.Phase2fertig = TTPBewerberItem.Phase2;

            let showUhStat1 = (TTPBewerberItem.IsUhStat1Transmitted !== undefined) ? true : false;
            this.setState({ showUhStat1: showUhStat1 })

            if (TTPBewerberItem.IsUhStat1Transmitted !== undefined) fields.Qualification.isUhStat1Transmitted = TTPBewerberItem.IsUhStat1Transmitted;
            if (TTPBewerberItem.OptionId !== null && TTPBewerberItem.OptionId !== 0) fields.Qualification.optionId = TTPBewerberItem.OptionId;
            if (TTPBewerberItem.OptionText !== null && TTPBewerberItem.OptionText !== '') fields.Qualification.optiontext = TTPBewerberItem.OptionText;

            if (TTPBewerberItem.tppOptionAuswahlItems !== null) {
                let liste = TTPBewerberItem.tppOptionAuswahlItems as [];
                if (liste.length > 0) {
                    var tmplHS = [] as IOptionItems[];
                    for (let u = 0; u < liste.length; u++) { tmplHS.push(liste[u] as IOptionItems); }
                    fields.Qualification.optionitems = tmplHS;
                }
            }
            var tmpyears = [];
            tmpyears.push({ Text: "", Value: "0" });

            var tmpnations = [];
            tmpnations.push({ Text: "", Value: "0" });

            var tmplevels = [];
            tmplevels.push({ Text: "", Value: "0" });

            var tmplevelsaustria = [];
            tmplevelsaustria.push({ Text: "", Value: "0" });

            if (TTPBewerberItem.UhStat1 !== undefined) {
                if (TTPBewerberItem.UhStat1.Years !== null) {
                    let liste2 = TTPBewerberItem.UhStat1.Years;

                    if (liste2.length > 0) {
                        for (let u = 0; u < liste2.length; u++) {
                            tmpyears.push({ text: liste2[u].Text, value: liste2[u].Value });
                        }
                        this.setState({ legalguardianyears: tmpyears })
                    }
                }

                if (TTPBewerberItem.UhStat1.EducationLevelsOthers !== null) {
                    let liste4 = TTPBewerberItem.UhStat1.EducationLevelsOthers;

                    if (liste4.length > 0) {
                        for (let u = 0; u < liste4.length; u++) {
                            tmplevels.push({ text: liste4[u].Text, value: liste4[u].Value });
                        }
                        this.setState({ legalguardianlevels: tmplevels })
                    }
                }

                if (TTPBewerberItem.UhStat1.Countries !== null) {
                    let liste3 = TTPBewerberItem.UhStat1.Countries;

                    if (liste3.length > 0) {
                        for (let u = 0; u < liste3.length; u++) {
                            tmpnations.push({ text: liste3[u].Text, value: liste3[u].Value });
                        }
                        this.setState({ legalguardiannations: tmpnations })
                    }
                }

                if (TTPBewerberItem.UhStat1.EducationLevelsAustria !== null) {
                    let liste5 = TTPBewerberItem.UhStat1.EducationLevelsAustria;

                    if (liste5.length > 0) {
                        for (let u = 0; u < liste5.length; u++) {
                            tmplevelsaustria.push({ text: liste5[u].Text, value: liste5[u].Value });
                        }
                        this.setState({ legalguardianlevelsaustria: tmplevelsaustria })
                    }
                }
            }

            this.setState({ fields, ready: true });
            this.LoadListCheck();
        }
        )
    }


    LoadListCheck() {
        let fields = this.state.fields;
        for (let u = 0; u < fields.Qualification.schoollist.length; u++) {
            let value = fields.Qualification.schoollist[u];
            if (value.nationID === null) value.nationID = 0;
        }

        for (let u = 0; u < fields.Qualification.universitylist.length; u++) {
            let value = fields.Qualification.universitylist[u];
            if (value.nationID === null) value.nationID = 0;
        }
        this.setState({ fields });
    }

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        let fields = this.state.fields;

        switch (event.target.name) {
            case "zugangcompletiondate": fields.Qualification["zugangcompletiondate"] = event.target.value;
                break;
            case "masterzugangcompletiondate": fields.Qualification["masterzugangcompletiondate"] = event.target.value;
                break;
        }
        this.setState({ fields });
    }

    selectedDropdown = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let fields = this.state.fields;

        switch (event.target.name) {
            case "zugangssuingcountry": fields.Qualification["zugangssuingcountry"] = Number(event.target.value);
                break;
            case "masterzugangssuingcountry": fields.Qualification["masterzugangssuingcountry"] = Number(event.target.value);
                break;
            case "motheryearofbirth": fields.Qualification["legalguardianmotheryearofbirth"] = Number(event.target.value);
                break;
            case "mothernationofbirth": fields.Qualification["legalguardianmothercountryofbirth"] = Number(event.target.value);
                break;
            case "mothernationofeducation": fields.Qualification["legalguardianmothercountryofhighesteducation"] = Number(event.target.value);
                break;
            case "motherlevelofeducation": fields.Qualification["legalguardianmotherlevelofhighesteducationothers"] = Number(event.target.value);
                break;
            case "motherlevelofeducationaustria": fields.Qualification["legalguardianmotherlevelofhighesteducationaustria"] = Number(event.target.value);
                break;
            case "fatheryearofbirth": fields.Qualification["legalguardianfatheryearofbirth"] = Number(event.target.value);
                break;
            case "fathernationofbirth": fields.Qualification["legalguardianfathercountryofbirth"] = Number(event.target.value);
                break;
            case "fathernationofeducation": fields.Qualification["legalguardianfathercountryofhighesteducation"] = Number(event.target.value);
                break;
            case "fatherlevelofeducation": fields.Qualification["legalguardianfatherlevelofhighesteducationothers"] = Number(event.target.value);
                break;
            case "fatherlevelofeducationaustria": fields.Qualification["legalguardianfatherlevelofhighesteducationaustria"] = Number(event.target.value);
                break;
        }

        this.setState({ fields });
    }

    setOption = (event: any) => {
        let fields = this.state.fields;
        for (let u = 0; u < fields.Qualification.optionitems.length; u++) {
            let value = fields.Qualification.optionitems[u];
            if (value.ID.toString() === event.target.value) value.selected = true;
            else value.selected = false;
        }
        this.setState({ fields });
    }

    clickSubmithandler = async (event: any) => {

        event.preventDefault();
        this.setState({ disabled: true })
        let errors = this.state.errors;
        errors["submiterror"] = false;
        this.setState({ errors });
        let scrollToY = this.handleValidation();
        if (scrollToY === 0) {

            if (this.state.accessToken !== "-1" && this.state.accessToken !== null) {

                let fields = this.state.fields;
                fields.Qualification.Phase2fertig = true;
                this.setState({fields});

                let json = JSON.stringify(this.state.fields.Qualification);
                const response = await SaveBewerberQualification(this.state.accessToken, json);

                if (response === 200) {
                    this.props.history.push('/documents');
                    // this.props.history.push({
                    //     pathname: '/documents',
                    //     search: 'applicationguid=' + this.state.fields.Qualification.stgGuid,
                    //     state: { accessToken: this.state.accessToken }
                    // })
                } else {
                    this.setState({ disabled: false })
                    let errors = this.state.errors;
                    errors["submiterror"] = true;
                    this.setState({ errors });
                }
            }
        } else {
            window.scrollTo(0, scrollToY)
            console.log('validation failed')
            this.setState({ disabled: false })
        }
    }

    onInputCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        let fields = this.state.fields;

        switch (event.target.name) {
            case "CBZugangsvorraussetzung_Nachreichung": fields.Qualification.zugangprovidedlater = event.target.checked;
                break;
            case "CBmasterZugangsvorraussetzung_Nachreichung": fields.Qualification.masterzugangprovidedlater = event.target.checked;
                break;
            case "CBzertifikat_Nachreichung": fields.Qualification.providedenglischzertifikat = event.target.checked;
                break;
        }

        this.setState({ fields });

    };
    handleValidation() {
        let fields = this.state.fields;
        let errors = this.state.errors;
        let scrollToY = 0;

        if (fields.Qualification.zugangprovidedlater === false) {
            if (!fields.Qualification["zugangcompletiondate"]) {
                scrollToY = 400;
                errors.Qualification["zugangcompletiondate"] = errors.Qualification["zugangcompletiondatetext"];
            } else {
                if (new Date(new Date(fields.Qualification["zugangcompletiondate"]).toDateString()) <= new Date(new Date().toDateString())) {
                    errors.Qualification["zugangcompletiondate"] = "";
                } else {
                    if (fields.Qualification.isMaster === true) errors.Qualification["zugangcompletiondate"] = errors.Qualification["zugangcompletiondatecheckDateTextMA"];
                    else errors.Qualification["zugangcompletiondate"] = errors.Qualification["zugangcompletiondatecheckDateText"];
                    scrollToY = 400;
                }
            }
        } else errors.Qualification["zugangcompletiondate"] = "";

        if (fields.Qualification["zugangssuingcountry"] > 0) {
            errors.Qualification["zugangssuingcountry"] = "";
        } else {
            errors.Qualification["zugangssuingcountry"] = errors.Qualification["zugangssuingcountrytext"];
            if (scrollToY === 0) scrollToY = 400;
        }

        if (fields.Qualification.isMaster === true) {
            if (fields.Qualification.masterzugangprovidedlater === false) {
                if (!fields.Qualification["masterzugangcompletiondate"]) {
                    if (scrollToY === 0) scrollToY = 800;
                    errors.Qualification["masterzugangcompletiondate"] = errors.Qualification["masterzugangcompletiondatetext"];
                } else {
                    if (new Date(new Date(fields.Qualification["masterzugangcompletiondate"]).toDateString()) <= new Date(new Date().toDateString())) {
                        errors.Qualification["masterzugangcompletiondate"] = "";
                    } else {
                        errors.Qualification["masterzugangcompletiondate"] = errors.Qualification["masterzugangcompletiondatecheckDateText"];
                        if (scrollToY === 0) scrollToY = 800;
                    }
                }

            } else errors.Qualification["masterzugangcompletiondate"] = "";

            if (fields.Qualification["masterzugangssuingcountry"] > 0) {
                errors.Qualification["masterzugangssuingcountry"] = "";
            } else {
                errors.Qualification["masterzugangssuingcountry"] = errors.Qualification["masterzugangssuingcountrytext"];
                if (scrollToY === 0) scrollToY = 800;
            }
        }

        if (fields.Qualification.optionId > 0) {
            let checked = false;
            for (let u = 0; u < fields.Qualification.optionitems.length; u++) {
                let value = fields.Qualification.optionitems[u];
                if (value.selected === true) checked = true;
            }

            if (checked === false) {
                errors.Qualification["optionlist"] = errors.Qualification["optionlisttext"];
                if (scrollToY === 0) scrollToY = 900;
            } else errors.Qualification["optionlist"] = "";
        }

        if (this.state.showUhStat1 === true && fields.Qualification.isUhStat1Transmitted === false) {
            if (fields.Qualification.legalguardianmothercountryofbirth === 0) {
                errors.Qualification["legalguardianmothercountryofbirth"] = errors.Qualification["legalguardiancountryofbirthtext"];
                if (scrollToY === 0) scrollToY = 1300;
            } else errors.Qualification["legalguardianmothercountryofbirth"] = "";

            if (fields.Qualification.legalguardianmothercountryofhighesteducation === 0) {
                errors.Qualification["legalguardianmothercountryofeducation"] = errors.Qualification["legalguardiancountryofeducationtext"];
                if (scrollToY === 0) scrollToY = 1400;
            } else errors.Qualification["legalguardianmothercountryofeducation"] = "";

            if (fields.Qualification.legalguardianmothercountryofhighesteducation === 1 && fields.Qualification.legalguardianmotherlevelofhighesteducationaustria === 0) {
                errors.Qualification["legalguardianmotherlevelofeducationaustria"] = errors.Qualification["legalguardianlevelofeducationtext"];
                if (scrollToY === 0) scrollToY = 1500;
            } else errors.Qualification["legalguardianmotherlevelofeducationaustria"] = "";

            if (fields.Qualification.legalguardianmothercountryofhighesteducation > 1 && fields.Qualification.legalguardianmotherlevelofhighesteducationothers === 0) {
                errors.Qualification["legalguardianmotherlevelofeducationothers"] = errors.Qualification["legalguardianlevelofeducationtext"];
                if (scrollToY === 0) scrollToY = 1500;
            } else errors.Qualification["legalguardianmotherlevelofeducationothers"] = "";

            if (fields.Qualification.legalguardianmotheryearofbirth === 0) {
                errors.Qualification["legalguardianmotheryearofbirth"] = errors.Qualification["legalguardianyearofbirthtext"];
                if (scrollToY === 0) scrollToY = 1200;
            } else errors.Qualification["legalguardianmotheryearofbirth"] = "";

            // ------------------

            if (fields.Qualification.legalguardianfathercountryofbirth === 0) {
                errors.Qualification["legalguardianfathercountryofbirth"] = errors.Qualification["legalguardiancountryofbirthtext"];
                if (scrollToY === 0) scrollToY = 1700;
            } else errors.Qualification["legalguardianfathercountryofbirth"] = "";

            if (fields.Qualification.legalguardianfathercountryofhighesteducation === 0) {
                errors.Qualification["legalguardianfathercountryofeducation"] = errors.Qualification["legalguardiancountryofeducationtext"];
                if (scrollToY === 0) scrollToY = 1800;
            } else errors.Qualification["legalguardianfathercountryofeducation"] = "";

            if (fields.Qualification.legalguardianfathercountryofhighesteducation === 1 && fields.Qualification.legalguardianfatherlevelofhighesteducationaustria === 0) {
                errors.Qualification["legalguardianfatherlevelofeducationaustria"] = errors.Qualification["legalguardianlevelofeducationtext"];
                if (scrollToY === 0) scrollToY = 1900;
            } else errors.Qualification["legalguardianfatherlevelofeducationaustria"] = "";

            if (fields.Qualification.legalguardianfathercountryofhighesteducation > 1 && fields.Qualification.legalguardianfatherlevelofhighesteducationothers === 0) {
                errors.Qualification["legalguardianfatherlevelofeducationothers"] = errors.Qualification["legalguardianlevelofeducationtext"];
                if (scrollToY === 0) scrollToY = 1900;
            } else errors.Qualification["legalguardianfatherlevelofeducationothers"] = "";

            if (fields.Qualification.legalguardianfatheryearofbirth === 0) {
                errors.Qualification["legalguardianfatheryearofbirth"] = errors.Qualification["legalguardianyearofbirthtext"];
                if (scrollToY === 0) scrollToY = 1600;
            } else errors.Qualification["legalguardianfatheryearofbirth"] = "";
        }


        this.setState({ errors, scrollToY: scrollToY });
        return scrollToY;
    }

    clickBackhandler = async (event: any) => {
        event.preventDefault();
        this.setState({ disabled: true })
        this.props.history.push('/personalData')
    }

    clickSavehandler = async (event: any) => {
        event.preventDefault();
        this.setState({ disabled: true })
        let errors = this.state.errors;
        errors["submiterror"] = false;
        this.setState({ errors });

        if (this.state.accessToken !== "-1" && this.state.accessToken !== null) {

            let fields = this.state.fields;
                fields.Qualification.Phase2fertig = true;
                this.setState({fields});
                
            let json = JSON.stringify(this.state.fields.Qualification);
            const response = await SaveBewerberQualification(this.state.accessToken, json);

            if (response === 200) {
                this.props.history.push('/application')

            } else {
                this.setState({ disabled: false })
                let errors = this.state.errors;
                errors["submiterror"] = true;
                this.setState({ errors });
            }
        }
    }

    render() {

        if (this.state.timeout === true && !this.state.ready) this.locationError();
        if (!this.state.ready)
            return <LoadingAnimation />;

        return (
            <div id="visual-portal-wrapper" className="clearfix">
                <HeaderLogoIntern />
                <div id="portal-columns" className="row">
                    <div id="portal-columns-content" className="container">
                        <h1>IMC Krems online application system</h1>
                        <HeaderStudiengangInfoField StudiengangsArtbezeichnung={this.state.fields.studienganginfo.Studiengangsartbezeichnung} StudiengangsOrtbezeichnung={this.state.fields.studienganginfo.Studiengangsortbezeichnung} Studiengangsbezeichnung={this.state.fields.studienganginfo.Studiengangsbezeichnung}
                        ></HeaderStudiengangInfoField>
                        <p className="description">Step 2 of 3: Qualification</p>
                        <form>
                            <fieldset id="Zugangsvorraussetzung">
                                <legend>Higher education entrance qualification</legend>
                                <span id="ZugangErklaerungLabel1"> <p className="discreet">
                                    To qualify for admission for the {this.state.fields.studienganginfo.Studiengangsartbezeichnung} {this.state.fields.studienganginfo.Studiengangsbezeichnung} in {this.state.fields.studienganginfo.Studiengangsortbezeichnung}, a proof of general university entrance qualification (e.g. High School Diploma or A-Levels) is required. Please provide correct data.</p>
                                </span>
                                {this.state.fields.Qualification.isMaster === false &&
                                    <span id="ZugangErklaerungLabel2">Only if you should apply before you obtain the general higher education qualification, please activate the checkbox "To be provided later". In this case you have to submit the proof of your general higher education entrance qualification until your start of studies.</span>
                                }

                                <ol className="form">
                                    <li id="hideIfTest" className="hiddenStructure" />
                                    <ul className="ulZugang">
                                        <li className="no-margin">
                                            <ul className="form">
                                                {this.state.fields.Qualification.zugangprovidedlater === false &&
                                                    <li id="li_maturadatum" className="w-100 no-margin">
                                                        <label htmlFor="closingDate">Completion date{(this.state.fields.Qualification.zugangprovidedlater === false || this.state.fields.Qualification.isMaster) && <em title="Required" className="fieldRequired" id="reqmaturadatum">*</em>}</label>

                                                        <input type="date" name='zugangcompletiondate' onChange={this.handleInputChange} value={this.state.fields.Qualification.zugangcompletiondate} />
                                                        <span style={{ color: "orange", float: "left" }}>{this.state.errors.Qualification.zugangcompletiondate}</span>
                                                    </li>}
                                                {this.state.fields.Qualification.isMaster === false &&
                                                    <li id="linachreichung" className="w-100 no-margin">
                                                        <div className="label-wrapper no-span">
                                                            <input type="checkbox" name="CBZugangsvorraussetzung_Nachreichung" id="CBZugangsvorraussetzung_Nachreichung" onChange={this.onInputCheckboxChange} checked={this.state.fields.Qualification.zugangprovidedlater} />
                                                            <label htmlFor="CBZugangsvorraussetzung_Nachreichung">To be provided later</label>
                                                        </div>
                                                    </li>}
                                            </ul>
                                        </li>
                                        <li className="clearfix no-margin" id="li_matura" >
                                            <label htmlFor="graduationState">Issuing country<em title="Required" className="fieldRequired" id="reqmaturaland" >*</em></label>
                                            <select required={true} name='zugangssuingcountry' onChange={this.selectedDropdown} value={this.state.fields.Qualification.zugangssuingcountry} >
                                                {this.state.nations.map((value, index) => <option key={index} value={value.value}>{value.text}</option>)}
                                            </select>
                                            <span style={{ color: "orange", float: "left" }}>{this.state.errors.Qualification.zugangssuingcountry}</span>
                                        </li>
                                    </ul>
                                </ol>
                            </fieldset>

                            {this.state.fields.Qualification.isMaster &&
                                <fieldset id="MasterFieldset1">
                                    <legend>Qualifying preliminary studies for the Master degree programme</legend>
                                    <span id="MasterErklaerungLabel1"> <p className="discreet">
                                        To qualify for admission for the {this.state.fields.studienganginfo.Studiengangsartbezeichnung} {this.state.fields.studienganginfo.Studiengangsbezeichnung} in {this.state.fields.studienganginfo.Studiengangsortbezeichnung}, a proof of your completed Bachelor studies or an equivalent undergraduate degree programme in a related subject is required.<br></br><br></br>
                                        If you are applying before completing your bachelor degree or an equivalent undergraduate degree programme, please activate the checkbox "To be provided later". In this case you have to submit the proof of your successfully completed bachelor studies or an equivalent undergraduate degree programme until the start of studies.
                                    </p></span>
                                    <ol className="form">
                                        <li id="hideIfTest" className="hiddenStructure" />
                                        <ul className="ulZugang">
                                            <li className="no-margin">
                                                <ul className="form">
                                                    {this.state.fields.Qualification.masterzugangprovidedlater === false &&
                                                        <li id="li_badatum" className="w-100 no-margin">
                                                            <label htmlFor="masterclosingDate">Completion date{(this.state.fields.Qualification.masterzugangprovidedlater === false) && <em title="Required" className="fieldRequired" id="reqmaturadatum">*</em>}</label>
                                                            <input type="date" name='masterzugangcompletiondate' onChange={this.handleInputChange} value={this.state.fields.Qualification.masterzugangcompletiondate} />
                                                            <span style={{ color: "orange", float: "left" }}>{this.state.errors.Qualification.masterzugangcompletiondate}</span>
                                                        </li>
                                                    }
                                                    <li id="linachreichung" className="w-100 no-margin">
                                                        <div className="label-wrapper no-span">
                                                            <input type="checkbox" name="CBmasterZugangsvorraussetzung_Nachreichung" id="CBmasterZugangsvorraussetzung_Nachreichung" onChange={this.onInputCheckboxChange} checked={this.state.fields.Qualification.masterzugangprovidedlater} />
                                                            <label htmlFor="CBmasterZugangsvorraussetzung_Nachreichung">To be provided later</label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="clearfix no-margin" id="li_matura" >
                                                <label htmlFor="mastergraduationState">Issuing country<em title="Required" className="fieldRequired" id="reqmaturaland" >*</em></label>
                                                <select required={true} name='masterzugangssuingcountry' onChange={this.selectedDropdown} value={this.state.fields.Qualification.masterzugangssuingcountry} >
                                                    {this.state.nations.map((value, index) => <option key={index} value={value.value}>{value.text}</option>)}
                                                </select>
                                                <span style={{ color: "orange", float: "left" }}>{this.state.errors.Qualification.masterzugangssuingcountry}</span>
                                            </li>
                                        </ul>
                                    </ol>
                                </fieldset>}

                            {this.state.fields.Qualification.uploadenglischzertifikat &&
                                <fieldset id="ZertifikatFieldset">
                                    <legend>Proof of English language proficiency</legend>
                                    <span id="MasterErklaerungLabel1"> <p className="discreet">
                                        For your application you must provide either an English language certificate (e.g. IELTS 5.5 or Cambridge certificate in Level B2) or a confirmation from your secondary school that you have completed your entire secondary school in English.
                                        <br /><br />In case you already have a relevant proof of English language proficiency, there is nothing to activate here. Please <b>activate the checkbox</b> "To be provided later" <b>only if you apply before you obtain the English language certificate.</b>
                                    </p></span>

                                    <ol className="form">
                                        <ul className="ulZugang">
                                            <li className="no-margin">
                                                <ul className="form">
                                                    <li id="lizertifikatnachreichung" className="w-100 no-margin">
                                                        <div className="label-wrapper no-span" style={{ marginTop: "0px" }}>
                                                            <input type="checkbox" name="CBzertifikat_Nachreichung" id="CBzertifikat_Nachreichung" onChange={this.onInputCheckboxChange} checked={this.state.fields.Qualification.providedenglischzertifikat} />
                                                            <label htmlFor="CBzertifikat_Nachreichung">To be provided later</label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </ol>
                                </fieldset>}

                            {this.state.fields.Qualification.optionId > 0 &&
                                <fieldset id="OptionFieldset">
                                    <legend>{this.state.fields.Qualification.optiontext}</legend>
                                    <span id="OptionErklaerungLabel1"> <p className="discreet">Please choose the option that applies to you.<em title="Required" className="fieldRequired" id="reqoption">*</em></p></span>
                                    <div onChange={this.setOption.bind(this)}>
                                        {this.state.fields.Qualification.optionitems.map((value, index) =>
                                            <div key={index + "div"} ><input key={index + "radio"} id={"option" + value.ID} type="radio" value={value.ID} name="option" title={value.Title} checked={value.selected} /><label key={index + "label"}>&nbsp;&nbsp;{value.Title}</label></div>
                                        )
                                        }

                                    </div>
                                    <span style={{ color: "orange", float: "left" }}>{this.state.errors.Qualification.optionlist}</span>
                                </fieldset>
                            }

                            {this.state.showUhStat1 === true &&  this.state.fields.Qualification.isUhStat1Transmitted === false &&
                                <fieldset id="UhStat1Fieldset">
                                    <legend>Mandatory statistical survey</legend>
                                    <p className="discreet">According to the <a rel='noreferrer' href='https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&amp;Gesetzesnummer=20011451' target='_blank'>Bildungsdokumentationsgesetz</a> (act governing educational documentation) &sect; 18 para. 6 and 7 and the <a href='https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&amp;Gesetzesnummer=20011989' target='_blank' rel='noreferrer'>Universit&auml;ts- und Hochschulstatistik- und Bildungsdokumentationsverordnung</a> (UHSBV - University and Higher Education Statistics and Education Documentation Ordinance) &sect;&sect; 26 and 27, applicants for transnational degree programmes offered in cooperation with Austrian universities and higher education institutions are obliged to complete the UHSTAT1 survey.<br /><br /><b>Information about your legal guardians</b><br />The following issues refer to your legal guardians (parents or persons who were in the role of the parents, e.g. stepparents or foster parents). If you should not know some details of your legal guardians, please select the answer option 'unknown'.</p>
                                    <p className="discreet"><b>Legal guardian 1/mother</b></p>
                                    <ol className="form">
                                        <ul className="ulmother">
                                            <li className="no-margin" style={{ width: 700 }}>
                                                <label htmlFor="motheryearofbirthState">Year of birth<em title="Required" className="fieldRequired" id="reqmotheryearofbirth" >*</em></label>
                                                <select required={true} name='motheryearofbirth' onChange={this.selectedDropdown} value={this.state.fields.Qualification.legalguardianmotheryearofbirth} >
                                                    {this.state.legalguardianyears.map((value, index) => <option key={index} value={value.value}>{value.text}</option>)}
                                                </select>
                                                <span style={{ color: "orange" }}>{this.state.errors.Qualification.legalguardianmotheryearofbirth}</span>
                                            </li>

                                            <li className="no-margin" style={{ display: "block", width: 700 }}>
                                                <label htmlFor="mothernationofbirthState">Country of birth (in today's borders)<em title="Required" className="fieldRequired" id="reqmothernationofbirth" >*</em></label>
                                                <select required={true} name='mothernationofbirth' onChange={this.selectedDropdown} value={this.state.fields.Qualification.legalguardianmothercountryofbirth} >
                                                    {this.state.legalguardiannations.map((value, index) => <option key={index} value={value.value}>{value.text}</option>)}
                                                </select>
                                                <span style={{ color: "orange" }}>{this.state.errors.Qualification.legalguardianmothercountryofbirth}</span>
                                            </li>

                                            <li className="no-margin" style={{ display: "block", width: 700 }}>
                                                <label htmlFor="mothernationofeducationState">Country of the highest completed level of education (in today's borders)<em title="Required" className="fieldRequired" id="reqmothernationofeducation" >*</em></label>
                                                <select required={true} name='mothernationofeducation' onChange={this.selectedDropdown} value={this.state.fields.Qualification.legalguardianmothercountryofhighesteducation} >
                                                    {this.state.legalguardiannations.map((value, index) => <option key={index} value={value.value}>{value.text}</option>)}
                                                </select>
                                                <span style={{ color: "orange" }}>{this.state.errors.Qualification.legalguardianmothercountryofeducation}</span>
                                            </li>

                                            {this.state.fields.Qualification.legalguardianmothercountryofhighesteducation > 1 &&
                                                <li id="limotherlevelother" className="no-margin" style={{ display: "block", width: 700 }}>
                                                    <label htmlFor="motherlevelofeducationState">Highest completed level of education<em title="Required" className="fieldRequired" id="reqmotherlevelofeducation" >*</em></label>
                                                    <select required={true} name='motherlevelofeducation' onChange={this.selectedDropdown} value={this.state.fields.Qualification.legalguardianmotherlevelofhighesteducationothers} >
                                                        {this.state.legalguardianlevels.map((value, index) => <option key={index} value={value.value}>{value.text}</option>)}
                                                    </select>
                                                    <span style={{ color: "orange" }}>{this.state.errors.Qualification.legalguardianmotherlevelofeducationothers}</span>
                                                </li>}

                                            {this.state.fields.Qualification.legalguardianmothercountryofhighesteducation === 1 &&
                                                <li id="limotherlevelajustria" className="no-margin" style={{ display: "block", width: 700 }}>
                                                    <label htmlFor="motherlevelofeducationaustriaState">Highest completed level of education<em title="Required" className="fieldRequired" id="reqmotherlevelofeducationaustria" >*</em></label>
                                                    <select required={true} name='motherlevelofeducationaustria' onChange={this.selectedDropdown} value={this.state.fields.Qualification.legalguardianmotherlevelofhighesteducationaustria} >
                                                        {this.state.legalguardianlevelsaustria.map((value, index) => <option key={index} value={value.value}>{value.text}</option>)}
                                                    </select>
                                                    <span style={{ color: "orange" }}>{this.state.errors.Qualification.legalguardianmotherlevelofeducationaustria}</span>
                                                </li>}
                                        </ul>
                                    </ol>
                                    <br />
                                    <p className="discreet"><b>Legal guardian 2/father</b></p>
                                    <ol className="form">
                                        <ul className="ulfather">
                                            <li className="no-margin" style={{ width: 700 }}>
                                                <label htmlFor="fatheryearofbirthState">Year of birth<em title="Required" className="fieldRequired" id="reqfatheryearofbirth" >*</em></label>
                                                <select required={true} name='fatheryearofbirth' onChange={this.selectedDropdown} value={this.state.fields.Qualification.legalguardianfatheryearofbirth} >
                                                    {this.state.legalguardianyears.map((value, index) => <option key={index} value={value.value}>{value.text}</option>)}
                                                </select>
                                                <span style={{ color: "orange" }}>{this.state.errors.Qualification.legalguardianfatheryearofbirth}</span>
                                            </li>

                                            <li className="no-margin" style={{ display: "block", width: 700 }}>
                                                <label htmlFor="fathernationofbirthState">Country of birth (in today's borders)<em title="Required" className="fieldRequired" id="reqfathernationofbirth" >*</em></label>
                                                <select required={true} name='fathernationofbirth' onChange={this.selectedDropdown} value={this.state.fields.Qualification.legalguardianfathercountryofbirth} >
                                                    {this.state.legalguardiannations.map((value, index) => <option key={index} value={value.value}>{value.text}</option>)}
                                                </select>
                                                <span style={{ color: "orange" }}>{this.state.errors.Qualification.legalguardianfathercountryofbirth}</span>
                                            </li>

                                            <li className="no-margin" style={{ display: "block", width: 700 }}>
                                                <label htmlFor="fathernationofeducationState">Country of the highest completed level of education (in today's borders)<em title="Required" className="fieldRequired" id="reqfathernationofeducation" >*</em></label>
                                                <select required={true} name='fathernationofeducation' onChange={this.selectedDropdown} value={this.state.fields.Qualification.legalguardianfathercountryofhighesteducation} >
                                                    {this.state.legalguardiannations.map((value, index) => <option key={index} value={value.value}>{value.text}</option>)}
                                                </select>
                                                <span style={{ color: "orange" }}>{this.state.errors.Qualification.legalguardianfathercountryofeducation}</span>
                                            </li>

                                            {this.state.fields.Qualification.legalguardianfathercountryofhighesteducation > 1 &&
                                                <li id="lifatherlevelother" className="no-margin" style={{ display: "block", width: 700 }}>
                                                    <label htmlFor="fatherlevelofeducationState">Highest completed level of education<em title="Required" className="fieldRequired" id="reqmfatherlevelofeducation" >*</em></label>
                                                    <select required={true} name='fatherlevelofeducation' onChange={this.selectedDropdown} value={this.state.fields.Qualification.legalguardianfatherlevelofhighesteducationothers} >
                                                        {this.state.legalguardianlevels.map((value, index) => <option key={index} value={value.value}>{value.text}</option>)}
                                                    </select>
                                                    <span style={{ color: "orange" }}>{this.state.errors.Qualification.legalguardianfatherlevelofeducationothers}</span>
                                                </li>}

                                            {this.state.fields.Qualification.legalguardianfathercountryofhighesteducation === 1 &&
                                                <li id="lifatherlevelajustria" className="no-margin" style={{ display: "block", width: 700 }}>
                                                    <label htmlFor="fatherlevelofeducationaustriaState">Highest completed level of education<em title="Required" className="fieldRequired" id="reqfatherlevelofeducationaustria" >*</em></label>
                                                    <select required={true} name='fatherlevelofeducationaustria' onChange={this.selectedDropdown} value={this.state.fields.Qualification.legalguardianfatherlevelofhighesteducationaustria} >
                                                        {this.state.legalguardianlevelsaustria.map((value, index) => <option key={index} value={value.value}>{value.text}</option>)}
                                                    </select>
                                                    <span style={{ color: "orange" }}>{this.state.errors.Qualification.legalguardianfatherlevelofeducationaustria}</span>
                                                </li>}
                                        </ul>
                                    </ol>
                                </fieldset>
                            }
                            <RequiredFieldDescription />
                            <div className="formControls clearfix">
                                {this.state.errors["submiterror"] && <SaveErrorRequest />}
                                <fieldset>
                                    <ul className="form">
                                        <li >
                                            <button type="button" id="ButtonBack" className="button blue left w-100" disabled={this.state.disabled} onClick={this.clickBackhandler} >Back</button>
                                        </li>
                                        <li >
                                            <button type="button" id="_Buttonright" className="button orangesmall right w-100" disabled={this.state.disabled} onClick={this.clickSubmithandler} >Continue to document upload</button>
                                        </li>
                                    </ul>
                                </fieldset>
                            </div>

                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }

}