export function getOAuthAuthorization(accessToken: string) {
  return {
    Authorization: 'Bearer ' + accessToken, //the token is a variable which holds the token    
  }
}

export function getOAuthAuthorizationHeader(accessToken: string) {
  var headers = {
      Authorization: 'Bearer ' + accessToken, //the token is a variable which holds the token
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'ngrok-skip-browser-warning':true
    }
    return headers;
}

export const getJsonContentTypeHTTPHeader = ["Content-Type", "application/json"]

export function validateEmail(email: any) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const anredeList = [
  { text: "", value: "" },
  { text: "male", value: "M" },
  { text: "female", value: "W" },
  { text: "diverse", value: "X" }
]

export const documenttypeList = [
  { text: "", value: "" },
  { text: "Passport", value: "Passport" },
  { text: "ID card", value: "ID card" }
]
export class Helper {
  private static getTrimmedElementValueById(id: string): string {
    return (document.getElementById(id) as HTMLInputElement).value.trim();
  }
}