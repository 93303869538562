import * as React from 'react'
import {HeaderLogo} from '../component/HeaderLogo'
import Footer from '../component/footer'
import {StudiengangsInfoFieldsItem} from '../items/StudiengangInfoItem'
import {HeaderStudiengangInfoField } from '../component/HeaderStudiengangInfo'
import { RouteComponentProps, Link } from "react-router-dom"
import { getaccesToken,getStudiengangInfo } from '../DataAccess'
import { isUndefined } from 'util'
interface State {
    fields:
    {
        studienganginfo : StudiengangsInfoFieldsItem
    }
    accessToken: string, ready: boolean, timeout: boolean
}

export default class SuccessfullApplied  extends React.Component<RouteComponentProps<any>, State> {


    constructor(props: any) {
        super(props)
        this.state = {
            fields: {
                studienganginfo: {
                    ID : 0, Studiengangsartbezeichnung:'', Studiengangsbezeichnung:'',Studiengangsortbezeichnung:'',GuideLink:''
                }
            },
            accessToken: '-1', ready: false, timeout: false
        };
        this.fetchdata();
    }

    locationError() {
        this.props.history.push({
            pathname: '/error'
        })
    }

    getStgID(): string {
        let data = sessionStorage.getItem('StgID');
        return data  || "";
    }

    fetchdata = () => {
        let stgID = this.getStgID();
        if (this.state.accessToken === "-1" || this.state.accessToken === null) {
            getaccesToken().
                then(res => {
                    if (res !== null && res !== isUndefined && typeof res === "string") {
                        this.setState({ accessToken: res.toString() })
                        getStudiengangInfo(this.state.accessToken, Number(stgID)) .then(res => {
                            let TTPStudiengangInfoItem = JSON.parse(JSON.stringify(res));
                            let fields = this.state.fields;
                            fields.studienganginfo.ID = TTPStudiengangInfoItem.ID;
                            fields.studienganginfo.Studiengangsartbezeichnung = TTPStudiengangInfoItem.Studiengangsartbezeichnung;
                            fields.studienganginfo.Studiengangsbezeichnung = TTPStudiengangInfoItem.Studiengangsbezeichnung;
                            fields.studienganginfo.Studiengangsortbezeichnung = TTPStudiengangInfoItem.Studiengangsortbezeichnung;
                            this.setState({ fields });
                        })
                }
        })} else 
        {
            getStudiengangInfo(this.state.accessToken, Number(stgID)) .then(res => {
                let TTPStudiengangInfoItem = JSON.parse(JSON.stringify(res));
                let fields = this.state.fields;

                fields.studienganginfo.ID = TTPStudiengangInfoItem.ID;
                fields.studienganginfo.Studiengangsartbezeichnung = TTPStudiengangInfoItem.Studiengangsartbezeichnung;
                fields.studienganginfo.Studiengangsbezeichnung = TTPStudiengangInfoItem.Studiengangsbezeichnung;
                fields.studienganginfo.Studiengangsortbezeichnung = TTPStudiengangInfoItem.Studiengangsortbezeichnung;
                this.setState({ fields });
            })
        }
    }
    render() {
        return (
        <div id="visual-portal-wrapper" className="clearfix">
        <HeaderLogo/>
     
        <div id="portal-columns" className="row">
            <div id="portal-columns-content" className="container">
                <h1>IMC Krems online application system</h1><br></br>
                <HeaderStudiengangInfoField StudiengangsArtbezeichnung={this.state.fields.studienganginfo.Studiengangsartbezeichnung} StudiengangsOrtbezeichnung={this.state.fields.studienganginfo.Studiengangsortbezeichnung} Studiengangsbezeichnung={this.state.fields.studienganginfo.Studiengangsbezeichnung} 
                    ></HeaderStudiengangInfoField>
                <p className="description"><strong>Online application successfully submitted!</strong><br></br></p>
                <p className="discreet">
                Thank you for submitting your online application for the {this.state.fields.studienganginfo.Studiengangsartbezeichnung} {this.state.fields.studienganginfo.Studiengangsbezeichnung} in {this.state.fields.studienganginfo.Studiengangsortbezeichnung}. <br></br>
                You will receive confirmation of your online application by e-mail in a few minutes. If you do not receive this e-mail, 
                please check your spam folder and add <a href="mailto:admission@imc.ac.at">admission@imc.ac.at</a> to your safe list. <br></br>
                <br></br>
                <strong>What happens next?</strong><br></br>
                We will now check your application to make sure it is complete and the details you have provided are correct.  
                This process can take several weeks. 
                <br></br><br></br>
                If we should require any additional information or documents for the verification process, we will contact you by e-mail. 
                Upon completion of the individual verification of your application, you will receive the invitation to the admission interview.
                <br></br><br></br>
                If you have any questions, please contact us at <a href="mailto:admission@imc.ac.at">admission@imc.ac.at</a>.
                </p>
            </div>
        </div>
       <Footer></Footer>
    </div>
    );
        }
}