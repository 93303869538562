import * as React from 'react'
import { Component } from 'react'
import { HeaderLogo } from '../component/HeaderLogo'
import Footer from '../component/footer'
import {StudiengangsInfoFieldsItem} from '../items/StudiengangInfoItem'
import { RouteComponentProps, Link } from "react-router-dom"
import { getaccesToken,getStudiengangInfo } from '../DataAccess'
import { isUndefined } from 'util';

interface State {
    fields:
    {
        studienganginfo : StudiengangsInfoFieldsItem
    }
    accessToken: string, ready: boolean, timeout: boolean
}

export default class Successfull extends React.Component<RouteComponentProps<any>, State> {
    constructor(props: any) {
        super(props)


        this.state = {
            fields: {
                studienganginfo: {
                    ID : 0, Studiengangsartbezeichnung:'', Studiengangsbezeichnung:'',Studiengangsortbezeichnung:'',GuideLink:''
                }
            },
            accessToken: '-1', ready: false, timeout: false
        };
        this.fetchdata();
    }

    locationError() {
        this.props.history.push({
            pathname: '/error'
        })
    }

    getStgID(): string {
        let data = sessionStorage.getItem('StgID');
        return data  || "";
    }

    fetchdata = () => {
        let stgID = this.getStgID();
        if (this.state.accessToken === "-1" || this.state.accessToken === null) {
            getaccesToken().
                then(res => {
                    if (res !== null && res !== isUndefined && typeof res === "string") {
                        this.setState({ accessToken: res.toString() })
                        getStudiengangInfo(this.state.accessToken, Number(stgID)) .then(res => {
                            let TTPStudiengangInfoItem = JSON.parse(JSON.stringify(res));
                            let fields = this.state.fields;
                            fields.studienganginfo.ID = TTPStudiengangInfoItem.ID;
                            fields.studienganginfo.Studiengangsartbezeichnung = TTPStudiengangInfoItem.Studiengangsartbezeichnung;
                            fields.studienganginfo.Studiengangsbezeichnung = TTPStudiengangInfoItem.Studiengangsbezeichnung;
                            fields.studienganginfo.Studiengangsortbezeichnung = TTPStudiengangInfoItem.Studiengangsortbezeichnung;
                            this.setState({ fields });
                           
                        })
                }
        })} else 
        {
            getStudiengangInfo(this.state.accessToken, Number(stgID)) .then(res => {
                let TTPStudiengangInfoItem = JSON.parse(JSON.stringify(res));
                let fields = this.state.fields;
                fields.studienganginfo.ID = TTPStudiengangInfoItem.ID;
                fields.studienganginfo.Studiengangsartbezeichnung = TTPStudiengangInfoItem.Studiengangsartbezeichnung;
                fields.studienganginfo.Studiengangsbezeichnung = TTPStudiengangInfoItem.Studiengangsbezeichnung;
                fields.studienganginfo.Studiengangsortbezeichnung = TTPStudiengangInfoItem.Studiengangsortbezeichnung;
                this.setState({ fields });
            })
        }
    }

    render() {
    return (
        <div id="visual-portal-wrapper" className="clearfix">
        <HeaderLogo/>
        <div id="portal-columns" className="row">
            <div id="portal-columns-content" className="container">
                <h1>IMC Krems online application system</h1>
                <p className="description">
                <b>Your registration for the {this.state.fields.studienganginfo.Studiengangsartbezeichnung} {this.state.fields.studienganginfo.Studiengangsbezeichnung} in {this.state.fields.studienganginfo.Studiengangsortbezeichnung}  was successful!</b></p>
                <p className="discreet">Thank you for registering! You will shortly receive an e-mail confirmation containing your personal access link. <br></br>
                Simply click on the link to start preparing your application. <br></br>If you do not receive this e-mail, please check your spam folder and add  <a href="mailto:admission@imc.ac.at">admission@imc.ac.at</a> to your safe list.<br></br><br></br>
                If you have any questions, please contact the IMC Krems admission team on <a href="mailto:admission@imc.ac.at">admission@imc.ac.at</a>
                </p>
            </div>
        </div>
       <Footer></Footer>
    </div>
       
    );
    }
}