import * as React from "react";
import { Link } from "react-router-dom";

export class HeaderLogo extends React.Component<{}, {}>{

    render() {

        return (
            <header>
                <div id="header" className="row">
                    <div id="banner" className="container">
                        <a accessKey="1" id="logo" target="_blank" rel="noreferrer" title="Startpage" href="https://www.imc.ac.at/en/international/transnational-degree-programmes/overview/">
                            <img src="https://www.imc.ac.at/fileadmin-imckrems/user_upload/Logos/imc_logo_web_2023_long.png" alt="IMC Krems GesmbH - University of Applied Sciences Austria" />
                        </a>
                    </div>
                </div>
            </header>
        );

    }
}

export class HeaderLogoIntern extends React.Component<{}, {}>{

    render() {

        return (
            <header>
                <div id="header" className="row">
                    <div id="banner" className="container">

                    <Link  to='/application' title="Landing Page" id="logo" >
                    <img src="https://www.imc.ac.at/fileadmin-imckrems/user_upload/Logos/imc_logo_web_2023_long.png" alt="IMC Krems GesmbH - University of Applied Sciences Austria" />
                    </Link>
                       
                    </div>
                </div>
            </header>
        );

    }
}