import PersonalDataFieldsItem from "../items/PersonalDataFieldsItem"
import {StudiengangsInfoFieldsItem} from "../items/StudiengangInfoItem"
import {HeaderStudiengangInfoField } from '../component/HeaderStudiengangInfo';
import PersonalDataErrorsItem from "../items/PersonalDataErrorsItem"
import { SaveErrorRequest } from '../component/SaveErrorRequest'
import { InputFieldText } from '../component/InputFieldText'
import { InputFieldSelect } from '../component/InputFieldSelect'

import { getaccesToken, getNationList, getBewerberGuid, UpdateBewerberStammdaten } from '../DataAccess'
import { isUndefined } from 'util'
import { HeaderLogoIntern } from '../component/HeaderLogo'
import Footer from '../component/footer'
import RequiredFieldDescription from '../component/requiredfieldDescription'
import { RouteComponentProps } from "react-router"
import { validateEmail, anredeList,documenttypeList } from '../Helper'
import { LoadingAnimation } from '../component/loadingComponent'
import { Component } from 'react'
import * as React from 'react'

interface State {
    fields:
    {
        personaldata: PersonalDataFieldsItem,
        studienganginfo : StudiengangsInfoFieldsItem
    },
    errors:
    {
        personaldata: PersonalDataErrorsItem,
        submiterror: boolean
    },
    disabled: boolean, submitted: boolean, accessToken: string, nations: any[], anredelist: any[], ready: boolean, timeout : boolean, documentypelist: any[],
}

export default class PersonalData extends Component<RouteComponentProps<any>, State> {

    constructor(props: any) {
        super(props)

        this.state = {
            fields: {
                personaldata: {
                    stgGuid: '', ID: 0, anrede: '', geschlecht: '',firstname: '', name: '', birthdate: '', nationID: 0, email: '', address: '', birthnationID: 0, birthplace: '', city: '', formerSurnames: '', phone: '', postalCode: '', skypeID: '', titel: '', titelafter: '', nationalityID: 0,
                    dokument_ausstellungsbehoerde: '', dokument_ausstellungsdatum : '', dokument_ausstellungsstaatID : 0, dokumentenart: '', dokumentennummer: '', vorwahl: ''
                },
                studienganginfo: {
                    ID : 0, Studiengangsartbezeichnung:'', Studiengangsbezeichnung:'',Studiengangsortbezeichnung:'',GuideLink:''
                }

            },
            errors: {
                personaldata: {
                    stgGuid: '', ID: 0, anrede: '', firstname: '', name: '', birthdate: '', nationID: '', email: '', address: '', birthnationID: '', birthplace: '', city: '', phone: '', nationalityID: '',
                    dokument_ausstellungsbehoerde: '', dokument_ausstellungsdatum : '', dokument_ausstellungsstaatID : '', dokumentenart: '', dokumentennummer: '',
                    anredetext: "Please select your salutation!",
                    nametext: "Please fill in your Surname!",
                    firstnametext: "Please fill in your First Name!",
                    birthdatetext: "Please fill in your birthdate!",
                    nationIDtext: "Please select your Country!",
                    emailtext: "Please fill in your  Email field!",
                    emailvalidtext: "Email is not valid!",
                    addresstext: "Please fill in your address!",
                    birthnationIDtext: "Please select your country of birth!",
                    birthplacetext: "Please fill in your plcae of birth!",
                    citytext: "Please fill in your town/city!",
                    phonetext: "Please fill in your phone number!",
                    nationalityIDtext: "Please select your nationality!",
                    
                    dokumentennummertext: "Please fill in the document number!",
                    dokumentenarttext : "Please select the document type!",
                    dokument_ausstellungsstaatIDtext :"Please select the issuing state of the document!",
                    dokument_ausstellungsdatumtext :"Please enter the date of issue!",
                    dokument_ausstellungsbehoerdetext : "Please enter the issuing authority!"

                },
                submiterror: false
            }, disabled: false, submitted: false, accessToken: '-1', nations: [], anredelist: anredeList, ready: false, timeout:false, documentypelist : documenttypeList
        }
        setTimeout(()=>this.setState({timeout: true}), 30000);

        let guid = this.getGuid();
        if ((guid === "" )) {
            this.props.history.push('/');
        }
        this.checkaccesstoken(guid);
    }

    locationError() {
        this.props.history.push({
            pathname: '/error'
        })
    }

    selectedDropdown = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let fields = this.state.fields;

        switch (event.target.name) {
            case "anrede": fields.personaldata["geschlecht"] = event.target.value;
                break;
            case "nationID": fields.personaldata["nationID"] = Number(event.target.value);
                break;
            case "birthnationID": fields.personaldata["birthnationID"] = Number(event.target.value);
                break;
            case "nationalityID": fields.personaldata["nationalityID"] = Number(event.target.value);
            break;
            case "dokument_ausstellungsstaatID": fields.personaldata["dokument_ausstellungsstaatID"] = Number(event.target.value);
            break;
            case "dokumentenart": fields.personaldata["dokumentenart"] = event.target.value;
            break;
        }

        this.setState({ fields });
    }

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        let fields = this.state.fields;

        switch (event.target.name) {
            case "firstname": fields.personaldata["firstname"] = event.target.value;
                break;
            case "surname": fields.personaldata["name"] = event.target.value;
                break;
            case "birthdate": fields.personaldata["birthdate"] = event.target.value;
                break;
              case "email": fields.personaldata["email"] = event.target.value;
                  break;
            case "formerSurnames": fields.personaldata["formerSurnames"] = event.target.value;
                break;
            case "phone": fields.personaldata["phone"] = event.target.value;
                break;
            case "postalCode": fields.personaldata["postalCode"] = event.target.value;
                break;
            case "city": fields.personaldata["city"] = event.target.value;
                break;
            case "address": fields.personaldata["address"] = event.target.value;
                break;
            case "birthplace": fields.personaldata["birthplace"] = event.target.value;
                break;
            case "dokumentennummer": fields.personaldata["dokumentennummer"] = event.target.value;
                break;
            case "dokument_ausstellungsdatum": fields.personaldata["dokument_ausstellungsdatum"] = event.target.value;
                break;
            case "dokument_ausstellungsbehoerde": fields.personaldata["dokument_ausstellungsbehoerde"] = event.target.value;
                break;
               
        }
        this.setState({ fields });
    }

    clickhandler = async (event: any) => {
        event.preventDefault();
        this.setState({ disabled: true })
        let errors = this.state.errors;
        errors["submiterror"] = false;
        this.setState({ errors });
        let srollToY = this.handleValidation();
        if (srollToY === 0) {
            console.log('validation successful')
            if (this.state.accessToken !== "-1" && this.state.accessToken !== null) {

                let json = JSON.stringify(this.state.fields.personaldata);
                const response = await UpdateBewerberStammdaten(this.state.accessToken, json);

                if (response === 200) {
                    this.props.history.push('/qualifications');
                } else {
                    this.setState({ disabled: false })
                    let errors = this.state.errors;
                    errors["submiterror"] = true;
                    this.setState({ errors });
                }
            }
        } else {
            window.scrollTo(0, srollToY)
            console.log('validation failed')
            this.setState({ disabled: false })
        }
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = this.state.errors;
        let srollToY = 0;
        if (!fields.personaldata["geschlecht"]) {
            srollToY = 190;
            errors.personaldata["anrede"] = errors.personaldata["anredetext"];
        } else errors.personaldata["anrede"] = "";

        if (fields.personaldata["nationalityID"] > 0) {
            errors.personaldata["nationalityID"] = "";
        } else {
            errors.personaldata["nationalityID"] = errors.personaldata["nationalityIDtext"];
            if(srollToY === 0) srollToY = 190;
        }

        if (!fields.personaldata["firstname"]) {
            if(srollToY === 0) srollToY = 290;
            errors.personaldata["firstname"] = errors.personaldata["firstnametext"];
        } else errors.personaldata["firstname"] = "";

        if (!fields.personaldata["birthdate"]) {
            if(srollToY === 0) srollToY = 290;
            errors.personaldata["birthdate"] = errors.personaldata["birthdatetext"];
        } else errors.personaldata["birthdate"] = "";

        if (!fields.personaldata["name"]) {
            if(srollToY === 0) srollToY = 390;
            errors.personaldata["name"] = errors.personaldata["nametext"];
        } else errors.personaldata["name"] = "";

        if (!fields.personaldata["birthplace"]) {
            if(srollToY === 0) srollToY = 390;
            errors.personaldata["birthplace"] = errors.personaldata["birthplacetext"];
        } else errors.personaldata["birthplace"] = "";

        if (fields.personaldata["birthnationID"] > 0) {
            errors.personaldata["birthnationID"] = "";
        } else {
            errors.personaldata["birthnationID"] = errors.personaldata["birthnationIDtext"];
            if(srollToY === 0) srollToY = 490;
        }

        if (fields.personaldata["nationID"] > 0) {
            errors.personaldata["nationID"] = "";
        } else {
            errors.personaldata["nationID"] = errors.personaldata["nationIDtext"];
            if(srollToY === 0) srollToY = 490;
        }

        if (!fields.personaldata["phone"] || fields.personaldata["phone"] === fields.personaldata["vorwahl"] || fields.personaldata["phone"].length <= fields.personaldata["vorwahl"].length) {
            if(srollToY === 0) srollToY = 640;
            errors.personaldata["phone"] = errors.personaldata["phonetext"];
        } else errors.personaldata["phone"] = "";

        if (!fields.personaldata["email"]) {
            if(srollToY === 0) srollToY = 640;
            errors.personaldata["email"] = errors.personaldata["emailtext"];
        } else {
            if (!validateEmail(fields.personaldata["email"])) {
                if(srollToY === 0) srollToY = 640;
                errors.personaldata["email"] = errors.personaldata["emailvalidtext"];
            }
            else errors.personaldata["email"] = "";
        }

        if (!fields.personaldata["city"]) {
            if(srollToY === 0) srollToY = 640;
            errors.personaldata["city"] = errors.personaldata["citytext"];
        } else errors.personaldata["city"] = "";

        if (!fields.personaldata["address"]) {
            if(srollToY === 0) srollToY = 640;
            errors.personaldata["address"] = errors.personaldata["addresstext"];
        } else errors.personaldata["address"] = "";

        if (!fields.personaldata["dokumentenart"]) {
            if(srollToY === 0) srollToY = 890;
            errors.personaldata["dokumentenart"] = errors.personaldata["dokumentenarttext"];
        } else errors.personaldata["dokumentenart"] = "";

        if (!fields.personaldata["dokumentennummer"]) {
            if(srollToY === 0) srollToY = 890;
            errors.personaldata["dokumentennummer"] = errors.personaldata["dokumentennummertext"];
        } else errors.personaldata["dokumentennummer"] = "";

        if (fields.personaldata["dokument_ausstellungsstaatID"] > 0) {
            errors.personaldata["dokument_ausstellungsstaatID"] = "";
        } else {
            errors.personaldata["dokument_ausstellungsstaatID"] = errors.personaldata["dokument_ausstellungsstaatIDtext"];
            if(srollToY === 0) srollToY = 890;
        }

        if (!fields.personaldata["dokument_ausstellungsdatum"]) {
            if(srollToY === 0) srollToY = 890;
            errors.personaldata["dokument_ausstellungsdatum"] = errors.personaldata["dokument_ausstellungsdatumtext"];
        } else errors.personaldata["dokument_ausstellungsdatum"] = "";

        if (!fields.personaldata["dokument_ausstellungsbehoerde"]) {
            if(srollToY === 0) srollToY = 890;
            errors.personaldata["dokument_ausstellungsbehoerde"] = errors.personaldata["dokument_ausstellungsbehoerdetext"];
        } else errors.personaldata["dokument_ausstellungsbehoerde"] = "";

        this.setState({ errors });
        return srollToY;
    }

    getGuid(): string {
        let data = sessionStorage.getItem('applicationguid');
        return data || "";
    }

    getUrlParams(): URLSearchParams {
        if (!this.props.location.search) return new URLSearchParams();
        return new URLSearchParams(this.props.location.search);
    }

    checkaccesstoken(guid: string) {
        console.info("checkaccesstoken")

        if (this.state.accessToken === "-1" || this.state.accessToken === null) {
            getaccesToken().then(res => {
                    if (res !== null && res !== isUndefined && typeof res === "string") {
                        this.setState({ accessToken: res.toString() })
                        this.getnationlist(guid);
                    }
                })
        } else this.getnationlist(guid);
    }

    getnationlist = async (guid: string) => {
        await getNationList(this.state.accessToken).then(res => {
                this.setState({ nations: JSON.parse(JSON.stringify(res)) })
                this.checkGuid(guid);
            })
    }

    checkGuid = async (guid: string) => {

        await getBewerberGuid(this.state.accessToken, guid)
            .then(res => {
                let TTPBewerberItem = JSON.parse(JSON.stringify(res));
                let fields = this.state.fields;

                fields.personaldata.ID = TTPBewerberItem.ID;
                fields.personaldata.stgGuid = TTPBewerberItem.StrGuid;

                if (fields.personaldata.stgGuid !== guid) {
                    this.props.history.push({
                        pathname: '/',
                        state: { accessToken: this.state.accessToken, nations: this.state.nations }
                    })
                }

                if (TTPBewerberItem.BirthnationID !== null) fields.personaldata.birthnationID = TTPBewerberItem.BirthnationID;
                if (TTPBewerberItem.NationalityID !== null) fields.personaldata.nationalityID = TTPBewerberItem.NationalityID;
                if (TTPBewerberItem.NationID !== null) fields.personaldata.nationID = TTPBewerberItem.NationID;

                if (TTPBewerberItem.FirstName !== null) fields.personaldata.firstname = TTPBewerberItem.FirstName;
                if (TTPBewerberItem.LastName !== null) fields.personaldata.name = TTPBewerberItem.LastName;
                if (TTPBewerberItem.Gender !== null) fields.personaldata.anrede = TTPBewerberItem.Gender;
                if (TTPBewerberItem.GenderNew !== null) fields.personaldata.geschlecht = TTPBewerberItem.GenderNew;
                
                if (TTPBewerberItem.Email !== null) fields.personaldata.email = TTPBewerberItem.Email;
                if (TTPBewerberItem.FormerSurnames !== null) fields.personaldata.formerSurnames = TTPBewerberItem.FormerSurnames;
                if (TTPBewerberItem.Birthplace !== null) fields.personaldata.birthplace = TTPBewerberItem.Birthplace;
                if (TTPBewerberItem.Phone !== null) fields.personaldata.phone = TTPBewerberItem.Phone;
                if (TTPBewerberItem.vorwahl !== null) fields.personaldata.vorwahl = TTPBewerberItem.vorwahl;
                if (TTPBewerberItem.PostalCode !== null) fields.personaldata.postalCode = TTPBewerberItem.PostalCode;
                if (TTPBewerberItem.City !== null) fields.personaldata.city = TTPBewerberItem.City;
                if (TTPBewerberItem.Address !== null) fields.personaldata.address = TTPBewerberItem.Address;

                if (TTPBewerberItem.birthdate !== null && TTPBewerberItem.birthdate !== '') fields.personaldata.birthdate = TTPBewerberItem.birthdate;

                fields.studienganginfo.ID = TTPBewerberItem.StudiengangID as number;
                fields.studienganginfo.Studiengangsartbezeichnung = TTPBewerberItem.Studiengangsartbezeichnung;
                fields.studienganginfo.Studiengangsbezeichnung = TTPBewerberItem.Studiengangsbezeichnung;
                fields.studienganginfo.Studiengangsortbezeichnung = TTPBewerberItem.Studiengangsortbezeichnung;

                if (TTPBewerberItem.dokument_ausstellungsstaatID !== null) fields.personaldata.dokument_ausstellungsstaatID = TTPBewerberItem.dokument_ausstellungsstaatID;
                if (TTPBewerberItem.dokumentennummer !== null) fields.personaldata.dokumentennummer = TTPBewerberItem.dokumentennummer;
                if (TTPBewerberItem.dokumentenart !== null) fields.personaldata.dokumentenart = TTPBewerberItem.dokumentenart;
                if (TTPBewerberItem.strdokument_ausstellungsdatum !== null && TTPBewerberItem.strdokument_ausstellungsdatum !== '' ) fields.personaldata.dokument_ausstellungsdatum = TTPBewerberItem.strdokument_ausstellungsdatum;
                if (TTPBewerberItem.dokument_ausstellungsbehoerde !== null) fields.personaldata.dokument_ausstellungsbehoerde = TTPBewerberItem.dokument_ausstellungsbehoerde;
              
                this.setState({ fields, ready: true });

            }
            )
    }

    render() {
        if(this.state.timeout === true && !this.state.ready) this.locationError();
        if (!this.state.ready)
            return <LoadingAnimation />;
        return (
            <div id="visual-portal-wrapper" className="clearfix">
                <HeaderLogoIntern />
                <div id="portal-columns" className="row">
                    <div id="portal-columns-content" className="container">
                        <h1>IMC Krems online application system</h1>
                        <HeaderStudiengangInfoField StudiengangsArtbezeichnung={this.state.fields.studienganginfo.Studiengangsartbezeichnung} StudiengangsOrtbezeichnung={this.state.fields.studienganginfo.Studiengangsortbezeichnung} Studiengangsbezeichnung={this.state.fields.studienganginfo.Studiengangsbezeichnung} 
                    ></HeaderStudiengangInfoField>
                        <p className="description">Step 1 of 3: Personal data</p>
                        <form>
                            <fieldset className="g480">
                                {/* <ol className="form"> */}
                                <legend>
                                    Personal data
                            </legend>
                                <div className="row">
                                    <InputFieldSelect Text="Gender" tabindex={1} htmlname="anrede" selectedDropdown={this.selectedDropdown} errorvalue={this.state.errors.personaldata["anrede"]}
                                        data={this.state.anredelist} value={this.state.fields.personaldata.geschlecht}  istooltip={true} tooltiptext='Diverse can only be chosen if this sex is clearly stated in your passport.' />

                                    <InputFieldSelect Text="Nationality" htmlname="nationalityID" tabindex={5} selectedDropdown={this.selectedDropdown} errorvalue={this.state.errors.personaldata["nationalityID"]}
                                        data={this.state.nations} value={this.state.fields.personaldata.nationalityID.toString()} required={true} />
                                </div>
                                <div className="row">
                                    <InputFieldText Type="text" Text="First Name" tabindex={2} htmlname="firstname" value={this.state.fields.personaldata.firstname} required={true}
                                        handleInputChange={this.handleInputChange} errorvalue={this.state.errors.personaldata["firstname"]} />

                                    <InputFieldText Type="date" Text="Date of birth" tabindex={6}  htmlname="birthdate" value={this.state.fields.personaldata.birthdate} required={true}
                                        handleInputChange={this.handleInputChange} errorvalue={this.state.errors.personaldata["birthdate"]} />
                                    
                                </div>
                                <div className="row">
                                <InputFieldText Type="text" Text="Surname" tabindex={3} htmlname="surname" value={this.state.fields.personaldata.name} required={true}
                                        handleInputChange={this.handleInputChange} errorvalue={this.state.errors.personaldata["name"]} />

                                <InputFieldText Type="text" Text="Place of birth" tabindex={7} htmlname="birthplace" value={this.state.fields.personaldata.birthplace} required={true}
                                        handleInputChange={this.handleInputChange} errorvalue={this.state.errors.personaldata["birthplace"]} />
                                </div>
                                <div className="row">
                                 
                                <InputFieldText Type="text" Text="Former surnames (only necessary if surname has changed)" htmlname="formerSurnames" tabindex={4} value={this.state.fields.personaldata.formerSurnames}
                                        handleInputChange={this.handleInputChange} istooltip={true} tooltiptext='If your surname has changed e.g. by marriage, then please enter your former surname.' />
                                    
                                <InputFieldSelect Text="Country of birth" htmlname="birthnationID" tabindex={8} selectedDropdown={this.selectedDropdown} errorvalue={this.state.errors.personaldata["birthnationID"]}
                                        data={this.state.nations} value={this.state.fields.personaldata.birthnationID.toString()} required={true} />
                                </div>
                               
                                {/* </ol> */}
                            </fieldset>
                            <fieldset>
                                <hr></hr><br></br>
                                <div className="row">
                                    <InputFieldSelect Text="Country" htmlname="nationID" tabindex={11} selectedDropdown={this.selectedDropdown} errorvalue={this.state.errors.personaldata["nationID"]}
                                        data={this.state.nations} value={this.state.fields.personaldata.nationID.toString()} required={true} />

                                    <InputFieldText Type="text" Text="Post code" tabindex={12} htmlname="postalCode" value={this.state.fields.personaldata.postalCode}
                                        handleInputChange={this.handleInputChange} ></InputFieldText>
                                    
                                </div>
                                <div className="row">
                                   

                                  
                                </div>
                                <div className="row">
                                    <InputFieldText Type="text" Text="Town/city" tabindex={13} htmlname="city" value={this.state.fields.personaldata.city} required={true}
                                        handleInputChange={this.handleInputChange} errorvalue={this.state.errors.personaldata["city"]} ></InputFieldText>

                                    <InputFieldText Type="text" Text="Complete and valid postal address" tabindex={14} htmlname="address" value={this.state.fields.personaldata.address} required={true}
                                        handleInputChange={this.handleInputChange} errorvalue={this.state.errors.personaldata["address"]} ></InputFieldText>
                                </div>
                                <div className="row">
                                <InputFieldText Type="text" Text="Telephone/mobile phone" tabindex={15} htmlname="phone" value={this.state.fields.personaldata.phone} required={true}
                                        handleInputChange={this.handleInputChange} errorvalue={this.state.errors.personaldata["phone"]} istooltip={true} tooltiptext="Please fill in the country code and your phone number. (e.g. +43 664123456)" />
                                    
                                    <InputFieldText Type="text" Text="Personal and valid e-mail address" tabindex={16} htmlname="email" value={this.state.fields.personaldata.email} required={true}
                                        handleInputChange={this.handleInputChange} errorvalue={this.state.errors.personaldata["email"]} ></InputFieldText>
                                </div>
                            </fieldset>
                            <hr></hr>
                            <fieldset>
                            <legend>
                                Detailed data to your identification document
                            </legend>
                                <div className="row">
                                    <InputFieldSelect Text="Document type" tabindex={18} htmlname="dokumentenart" selectedDropdown={this.selectedDropdown} errorvalue={this.state.errors.personaldata["dokumentenart"]}
                                        data={this.state.documentypelist} value={this.state.fields.personaldata.dokumentenart}  required={true} />

                                    <InputFieldText Type="text" Text="Document number" tabindex={19} htmlname="dokumentennummer" value={this.state.fields.personaldata.dokumentennummer} required={true}
                                        handleInputChange={this.handleInputChange} errorvalue={this.state.errors.personaldata["dokumentennummer"]} />
                                </div>
                                <div className="row">
                                    <InputFieldSelect Text="Issuing state of the document" tabindex={20} htmlname="dokument_ausstellungsstaatID" selectedDropdown={this.selectedDropdown} errorvalue={this.state.errors.personaldata["dokument_ausstellungsstaatID"]}
                                        data={this.state.nations} value={this.state.fields.personaldata.dokument_ausstellungsstaatID.toString()}  required={true} />

                                    <InputFieldText Type="date" Text="Date of issue" tabindex={21} htmlname="dokument_ausstellungsdatum" value={this.state.fields.personaldata.dokument_ausstellungsdatum} required={true}
                                        handleInputChange={this.handleInputChange} errorvalue={this.state.errors.personaldata["dokument_ausstellungsdatum"]} />
                                </div>
                                <div className="row">
                                    <InputFieldText Type="text" Text="Issuing authority" tabindex={22} htmlname="dokument_ausstellungsbehoerde" value={this.state.fields.personaldata.dokument_ausstellungsbehoerde} required={true}
                                        handleInputChange={this.handleInputChange} errorvalue={this.state.errors.personaldata["dokument_ausstellungsbehoerde"]} ></InputFieldText>
                                    <div className="col-12 col-md form-group form-item"></div>
                                </div>

                                </fieldset>
                            <RequiredFieldDescription />
                            <div className="formControls clearfix">
                                {this.state.errors["submiterror"] && <SaveErrorRequest />}
                                <button type="submit" className="button orange right" tabIndex={23} disabled={this.state.disabled} onClick={this.clickhandler}>Continue to qualifications</button><br /><br />
                            </div>
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }

}