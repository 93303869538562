import axios from 'axios';
import { getOAuthAuthorizationHeader, getOAuthAuthorization } from './Helper';
import { IRequestDokumentItem, IRequestFotoItem, IRequestFotoFileItem } from './interface/Interface';

export async function submitRegistration(accessToken: string, body: string) {

  var url = process.env.REACT_APP_DATA_API + "api/TPPBewerber/Submit";

  return await axios.post(url, body, { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.status
    }).catch(err => {
      console.error(err)
    })
}

export async function UpdateBewerberStammdaten(accessToken: string, body: string) {

  var url = process.env.REACT_APP_DATA_API + "api/TPPBewerber/UpdateBewerberStammdaten";

  return await axios.post(url, body, { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.status
    }).catch(err => {
      console.error(err)
    })
}

export async function SaveBewerberQualification(accessToken: string, body: string) {

  var url = process.env.REACT_APP_DATA_API + "api/TPPBewerber/SaveBewerberQualification";

  return await axios.post(url, body, { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.status
    }).catch(err => {
      console.error(err)
    })
}

export async function SaveBewerberDocument(accessToken: string, body: string) {

  var url = process.env.REACT_APP_DATA_API + "api/TPPBewerber/SaveBewerberDocument";

  return await axios.post(url, body, { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.status
    }).catch(err => {
      console.error(err)
    })
}

export async function SaveBewerberNachreichungDocument(accessToken: string, body: string) {

  var url = process.env.REACT_APP_DATA_API + "api/TPPBewerber/SaveBewerberNachreichungDocument";

  return await axios.post(url, body, { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.status
    }).catch(err => {
      console.error(err)
    })
}

export async function FinalizeBewerbung(accessToken: string, body: string) {

  var url = process.env.REACT_APP_DATA_API + "api/TPPBewerber/FinalizeBewerbung";

  return await axios.post(url, body, { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.status
    }).catch(err => {
      console.error(err)
    })
}

export async function getaccesToken() {
  var headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
  var url1 = process.env.REACT_APP_DATA_API + "Token"

  return await axios.post(url1, "grant_type=password&username=" + process.env.REACT_APP_DATA_API_USERNAME + "&password=" + process.env.REACT_APP_DATA_API_PASSWORD,
    {
      headers
    })
    .then(response => {
      if (response.status === 200)
        return response.data.access_token
      throw Error(response.statusText)
    }).catch(response => {
      return "Error getaccesToken!"
    })
}

export async function getcheckfristzeit(accessToken: string, studiengangID: number, semester: string) {
  var url = process.env.REACT_APP_DATA_API + "api/TPPBewerber/CheckFristzeit?studiengangID=" + studiengangID + "&semester=" + semester;
  return await axios.get(url,
    { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.data.fristzeititem
    }).catch(response => {
      console.error("Error checkfristzeit")
    })

}

export async function getNationList(accessToken: string) {

  var url2 = process.env.REACT_APP_DATA_API + "api/TPPBewerber/GetNationen";

  return await axios.get(url2,
    { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      var nationsData = response.data.dataitems;
      var tmpnations = [];
      tmpnations.push({ text: "", value: "0" });
      for (let u = 0; u < nationsData.length; u++) {
        tmpnations.push({ text: nationsData[u].Text, value: nationsData[u].Value });
      }
      return tmpnations;
    }).catch(response => {
      console.error("Error GetNationen")
    })
}

export async function getStudiengangsList(accessToken: string) {

  var url2 = process.env.REACT_APP_DATA_API + "api/TPPBewerber/GetTppStudiengangList?erhalterID=1";

  return await axios.get(url2,
    { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      var stgData = response.data.dataitems;
      var tmpstg = [];
      tmpstg.push({ text: "", value: "0" });
      for (let u = 0; u < stgData.length; u++) {
        tmpstg.push({ text: stgData[u].Text, value: stgData[u].Value });
      }
      return tmpstg;
    }).catch(response => {
      console.error("Error getStudiengangsList")
    })
}

export async function getSprachenList(accessToken: string) {

  var url2 = process.env.REACT_APP_DATA_API + "api/TPPBewerber/GetSprachen";

  return await axios.get(url2,
    { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      var languagesData = response.data.dataitems;
      var tmplanguages = [];
      tmplanguages.push({ text: "", value: "0" });
      for (let u = 0; u < languagesData.length; u++) {
        tmplanguages.push({ text: languagesData[u].Text, value: languagesData[u].Value });
      }
      return tmplanguages;
    }).catch(response => {
      console.error("Error GetSprachen")
    })
}

export async function getSprachenreferenzenList(accessToken: string) {

  var url2 = process.env.REACT_APP_DATA_API + "api/TPPBewerber/GetSprachenreferenzen";

  return await axios.get(url2,
    { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      var levelsData = response.data.dataitems;
      var tmplevels = [];
      tmplevels.push({ text: "", value: "0" });
      for (let u = 0; u < levelsData.length; u++) {
        tmplevels.push({ text: levelsData[u].Text, value: levelsData[u].Value });
      }
      return tmplevels;
    }).catch(response => {
      console.error("Error GetSprachen")
    })
}

export async function getBewerberGuid(accessToken: string, guid: string) {
  var url2 = process.env.REACT_APP_DATA_API + "api/TPPBewerber/GetTTPBewerberGUID?guid=" + guid;
  return await axios.get(url2,
    { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.data.bewerber
    }).catch(response => {
      console.error("Error GetTTPBewerberGUID")
    })

}

export async function getStudiengangInfo(accessToken: string, stgID: number) {
  var url2 = process.env.REACT_APP_DATA_API + "api/TPPBewerber/GetStudiengangInfo?stgID=" + stgID;
  return await axios.get(url2,
    { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.data.studiengangitem
    }).catch(response => {
      console.error("Error GetStudiengangInfo")
    })

}

export async function getQualificationGuid(accessToken: string, guid: string) {
  var url2 = process.env.REACT_APP_DATA_API + "api/TPPBewerber/GetTPPBewerberQualification?guid=" + guid;
  return await axios.get(url2,
    { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.data.bewerber
    }).catch(response => {
      console.error("Error getQualificationGuid")
    })

}

export async function getApplicationGuid(accessToken: string, guid: string) {
  var url2 = process.env.REACT_APP_DATA_API + "api/TPPBewerber/GetTPPBewerberApplication?guid=" + guid;
  return await axios.get(url2,
    { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.data.bewerber
    }).catch(response => {
      console.error("Error getApplicationGuid")
    })

}

export async function getDocumentGuid(accessToken: string, guid: string) {
  var url2 = process.env.REACT_APP_DATA_API + "api/TPPBewerber/GetTPPBewerberDocumenteGUID?guid=" + guid;
  return await axios.get(url2,
    { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.data.bewerber
    }).catch(response => {
      console.error("Error getDocumentGuid")
    })

}


export async function getSubsequentDocumentGuid(accessToken: string, guid: string) {
  var url2 = process.env.REACT_APP_DATA_API + "api/TPPBewerber/GetTPPBewerberNachreichungDocumenteGUID?guid=" + guid;
  return await axios.get(url2,
    { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.data.bewerber
    }).catch(response => {
      console.error("Error getDocumentGuid")
    })

}





export async function getMotivationGuid(accessToken: string, guid: string) {
  var url2 = process.env.REACT_APP_DATA_API + "api/TPPBewerber/GetTPPMotivationschreibenGUID?guid=" + guid;
  return await axios.get(url2,
    { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.data.bewerber
    }).catch(response => {
      console.error("Error GetTPPMotivationschreibenGUID")
    })

}

export async function getDownloadDokumentGuid(accessToken: string, guid: string, id: number) {
  var url2 = process.env.REACT_APP_DATA_API + "api/TPPBewerber/GetTPPDownloadDokumentGUID?id=" + id + "&guid=" + guid;
  return await axios.get(url2,
    { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.data.bewerber
    }).catch(response => {
      console.error("Error getDownloadDokumentGuid")
    })

}

export async function getDownloadFotoGuid(accessToken: string, guid: string, id: number) {
  var url2 = process.env.REACT_APP_DATA_API + "api/TPPBewerber/GetTPPDownloadFotoGUID?id=" + id + "&guid=" + guid;
  return await axios.get(url2,
    { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.data.bewerber
    }).catch(response => {
      console.error("Error GetTPPDownloadFotoGUID")
    })

}

export async function SaveBewerberMotivation(accessToken: string, body: string) {

  var url = process.env.REACT_APP_DATA_API + "api/TPPBewerber/SaveBewerberMotivation";

  return await axios.post(url, body, { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.status
    }).catch(err => {
      console.error(err)
    })
}

export async function SaveBewerberMotivationOneField(accessToken: string, body: string) {

  var url = process.env.REACT_APP_DATA_API + "api/TPPBewerber/SaveBewerberMotivationOneField";

  return await axios.post(url, body, { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.status
    }).catch(err => {
      console.error(err)
    })
}

export async function UploadDocument(accessToken: string, body: string) {

  var url = process.env.REACT_APP_DATA_API + "api/TPPBewerber/UploadDokument";

  return await axios.post(url, body, { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.data.bewerber
    }).catch(err => {
      console.error(err)
    })
}

export async function UploadDocumentForm(accessToken: string, body: IRequestDokumentItem) {

  var url = process.env.REACT_APP_DATA_API + "api/TPPBewerber/UploadDokumentForm";

  return await axios.post(url, body, {
    headers: {
      ...getOAuthAuthorization(accessToken), "Content-Type": "multipart/formdata"
    },
    transformRequest: [(data: IRequestDokumentItem, header) => {
      return createFormData(data, "document", "filename");
    }]
  })
    .then(response => {
      return response.data.bewerber
    }).catch(err => {
      console.error(err)
    })
}

const createFormData = (data: Record<string, any>, documentIdentifier: string, filenameIdentifier: string) => {
  const requestBody = new FormData();
  const filename = data[filenameIdentifier];
  Object.entries(data).forEach(value => {
    if (value[0] === documentIdentifier)
      requestBody.append(value[0], value[1], filename);
    else {
      requestBody.append(value[0], value[1]);
    }
  });
  return requestBody;
}

export async function UploadFoto(accessToken: string, body: string) {

  var url = process.env.REACT_APP_DATA_API + "api/TPPBewerber/UploadFoto";

  return await axios.post(url, body, { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.data.bewerber
    }).catch(err => {
      console.error(err)
    })
}

export async function UploadFotoForm(accessToken: string, body: IRequestFotoItem) {

  var url = process.env.REACT_APP_DATA_API + "api/TPPBewerber/UploadFotoForm";

  return await axios.post(url, body, {
    headers: {
      ...getOAuthAuthorization(accessToken), "Content-Type": "multipart/formdata"
    },
    transformRequest: [(data: IRequestDokumentItem, header) => {
      return createFormData(data, "document", "filename");
    }]
  })
    .then(response => {
      return response.data.bewerber
    }).catch(err => {
      console.error(err)
    })
}

export async function UploadFotoFileForm(accessToken: string, body: IRequestFotoFileItem) {

  var url = process.env.REACT_APP_DATA_API + "api/TPPBewerber/UploadFotoForm";

  return await axios.post(url, body, {
    headers: {
      ...getOAuthAuthorization(accessToken), "Content-Type": "multipart/formdata"
    },
    transformRequest: [(data: IRequestDokumentItem, header) => {
      return createFormData(data, "document", "filename");
    }]
  })
    .then(response => {
      return response.data.bewerber
    }).catch(err => {
      console.error(err)
    })
}

export async function TestData(accessToken: string, body: string) {

  var url = process.env.REACT_APP_DATA_API + "api/TPPBewerber/TestData";

  return await axios.post(url, body, { headers: getOAuthAuthorizationHeader(accessToken) })
    .then(response => {
      return response.data.bewerber
    }).catch(err => {
      console.error(err)
    })
}